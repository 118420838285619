import '@ncwallet-app/ui/src/assets/fonts';
import '@ncwallet-app/ui/src/assets/globalWebStyles';

import {PortalProvider} from '@gorhom/portal';
import {RootProvider} from '@ncwallet-app/core';
import {NavigationRoot} from '@ncwallet-app/navigation';
import {useFallbackRenderErrorsHandler} from '@ncwallet-app/navigation/src/Navigation/AuthStack/RenderErrorHandler';
import {FlashMessageInstance, Launcher} from '@ncwallet-app/ui';
import {ErrorBoundary} from '@sentry/react';
import React from 'react';
import {StyleSheet} from 'react-native';
import {GestureHandlerRootView} from 'react-native-gesture-handler';
import {SafeAreaProvider} from 'react-native-safe-area-context';

import HeadThemeColor from './HeadThemeColor';
import {TelegramRootServiceFactoryImpl} from './Root';

const factory = new TelegramRootServiceFactoryImpl();

export default function App() {
  const fallbackRenderErrorsHandler = useFallbackRenderErrorsHandler();
  return (
    <GestureHandlerRootView style={styles.root}>
      <SafeAreaProvider>
        <RootProvider rootServiceFactory={factory}>
          <ErrorBoundary fallback={fallbackRenderErrorsHandler}>
            <HeadThemeColor />
            <PortalProvider>
              <Launcher>
                <NavigationRoot />
              </Launcher>
              <FlashMessageInstance />
            </PortalProvider>
          </ErrorBoundary>
        </RootProvider>
      </SafeAreaProvider>
    </GestureHandlerRootView>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
});

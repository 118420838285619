import type {CurrencyDescription, InOutCurrency} from '@ncwallet-app/core';
import type {AddressType} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import {useIsDimensions} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import type React from 'react';

import type {BuyLinkBindingState} from '../../../Navigation/HomeStack/BuyLinkBinding/BuyLinkBindingState';
import {ErrorModal} from '../ErrorModal';
import LgBuyTabView from './LgBuyTabView';
import SmBuyTabView from './SmBuyTabView';

export type BuyTabViewProps = {
  walletHeader: React.ReactNode;
  amount: BuyLinkBindingState['amount'];
  currencyCode: BuyLinkBindingState['currencyCode'];
  cryptoCurrency: CurrencyDescription | undefined;
  setAmount: BuyLinkBindingState['setAmount'];
  onSubmit: BuyLinkBindingState['onSubmit'];
  error: BuyLinkBindingState['buyError'];
  disabled?: BuyLinkBindingState['disabled'];
  isBalanceHidden: boolean;
  addressName?: string;
  addressNameShown: boolean;
  networks?: InOutCurrency[] | undefined;
  contractType: string | null | undefined;
  shareShown: boolean;
  addressType?: AddressType;
  walletAddress: string;
  onShare: (data: string) => void;
  onAddresses: () => void;
  onAddressTypePress: () => void;
  goToRoot: () => void;
  onCurrencyPress: () => void;
  resetError: () => void;
};

export default observer(function BuyTabView(props: BuyTabViewProps) {
  const isLg = useIsDimensions('lg');

  return (
    <>
      {isLg ? <LgBuyTabView {...props} /> : <SmBuyTabView {...props} />}
      <ErrorModal code={props.error.modal?.code} onCancel={props.resetError} />
    </>
  );
});

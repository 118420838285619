import {useStrings, useTheme, variance} from '@ncwallet-app/core';
import type {LocaleDict} from '@ncwallet-app/localization/locale/LocaleStrings';
import {ArrowRightSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import {Text, View} from 'react-native';

import type {SettingsItemProps} from '../UserSettingsScreens/SharedComponents/SettingsItem';
import {
  ItemCol,
  ItemLabel,
  ItemTouchableWithLabel,
  TitleWithLabel,
} from '../UserSettingsScreens/SharedComponents/SettingsItem';
import type {DocumentButtonBadgeVariant} from './DocumentButtonBadgeVariant';
import {mapBadgeVariantToColor} from './DocumentButtonBadgeVariant';

export type DocumentButtonProps = SettingsItemProps & {
  badge?: {
    text: string;
    kind: DocumentButtonBadgeVariant;
  };
};

export type DocumentKind = 'passport' | 'id_card' | 'utility_bill' | 'other';

export const getDocumentStrings = (strings: LocaleDict) => {
  return {
    passport: strings['accountDocumentType.passport'],
    id_card: strings['accountDocumentType.id_card'],
    utility_bill: strings['accountDocumentType.utility_bill'],
    other: strings['accountDocumentType.other'],
  };
};

export const useGetDocumentTitle = (documentKind: DocumentKind) => {
  const strings = useStrings();
  return useMemo(
    () => getDocumentStrings(strings)[documentKind],
    [strings, documentKind],
  );
};

export default observer(function DocumentButton(props: DocumentButtonProps) {
  const {onPress, title, error, label, disabled, Icon, badge} = props;
  const DefaultIcon = Icon ?? ArrowRightSvg;
  const theme = useTheme();
  const badgeColor = mapBadgeVariantToColor(theme);
  const titleTranslated = useGetDocumentTitle(title as DocumentKind);
  const _styles = useMemo(() => {
    return {
      touchable: {
        backgroundColor: badge && badgeColor.get(badge.kind)?.secondary,
        opacity: 1,
      },
      touchableBody: {
        paddingVertical: 10,
      },
      badge: {
        backgroundColor: badge && badgeColor.get(badge.kind)?.primary,
      },
    };
  }, [badge, badgeColor]);

  return (
    <ItemTouchableWithLabel
      onPress={onPress}
      Icon={DefaultIcon}
      disabled={disabled}
      style={_styles.touchable}
      bodyStyle={_styles.touchableBody}>
      <ItemCol>
        {!!label && <ItemLabel>{label}</ItemLabel>}
        <TitleWithLabel error={error}>{titleTranslated}</TitleWithLabel>
      </ItemCol>
      {badge && (
        <BadgeView style={_styles.badge}>
          <BadgeText>{badge.text}</BadgeText>
        </BadgeView>
      )}
    </ItemTouchableWithLabel>
  );
});

const BadgeView = variance(View)(() => ({
  root: {
    marginLeft: 10,
    paddingHorizontal: 5,
    paddingVertical: 2,
    borderRadius: 4,
  },
}));

const BadgeText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 12,
    color: theme.palette.whitey,
  },
}));

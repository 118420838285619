import {variance} from '@ncwallet-app/core';
import {TouchableWithoutFeedback} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import type {ComponentProps, PropsWithChildren} from 'react';
import React, {useCallback, useEffect, useRef} from 'react';
import type {StyleProp, View, ViewStyle} from 'react-native';
import {Platform} from 'react-native';
import {View as AnimatableView} from 'react-native-animatable';

export type PinKeyboardButtonWebProps = PropsWithChildren<
  ComponentProps<typeof TouchableWithoutFeedback>
> & {
  isActive?: boolean;
  style?: StyleProp<ViewStyle>;
};

export default observer(function PinKeyboardButtonWeb(
  props: PinKeyboardButtonWebProps,
) {
  const {isActive, children, onPress, ...rest} = props;
  const animatableRef = useRef<AnimatableView & View>(null);

  const handlePress = useCallback(() => {
    onPress?.();
    animatableRef.current?.transition(
      {opacity: 0.5},
      {opacity: 0},
      300,
      'ease',
    );
  }, [onPress]);

  useEffect(() => {
    if (isActive) {
      animatableRef.current?.transition(
        {opacity: 0.5},
        {opacity: 0},
        300,
        'ease',
      );
    }
  }, [isActive, animatableRef]);

  return (
    <TouchableWithoutFeedback {...rest} onPress={handlePress}>
      {children}
      <KeyHighlight
        useNativeDriver={Platform.OS !== 'web'}
        duration={200}
        ref={animatableRef}
      />
    </TouchableWithoutFeedback>
  );
});

const KeyHighlight = variance(AnimatableView)(theme => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.whitey,
    opacity: 0,
  },
}));

import {sized, useStrings, variance} from '@ncwallet-app/core';
import {ModalContainer, TouchableOpacity} from '@ncwallet-app/ui';
import {BucketSvg} from '@ncwallet-app/ui/src/assets/svg/colored';
import {CrossSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

import type {DeleteAccountScreenBodyProps} from './DeleteAccountScreenBody';
import DeleteAccountScreenBody from './DeleteAccountScreenBody';

export type DeleteAccountModalProps = DeleteAccountScreenBodyProps;

export default observer(function DeleteAccountModal(
  props: DeleteAccountModalProps,
) {
  const strings = useStrings();
  return (
    <Backdrop>
      <Card>
        <CardHeader>
          <CardHeaderText>{strings['deleteAccountModal.title']}</CardHeaderText>
          <TouchableOpacity
            onPress={props.onClose}
            testID="approve-remove-btn-close">
            <CrossIcon />
          </TouchableOpacity>
        </CardHeader>
        <Bucket />
        <DeleteAccountScreenBody {...props} />
      </Card>
    </Backdrop>
  );
});

const BucketIcon = sized(BucketSvg, 120, 120);
const CrossIcon = variance(CrossSvg)(
  () => ({
    root: {},
  }),
  theme => ({
    width: 22,
    height: 22,
    color: theme.palette.textMain,
  }),
);

const Bucket = variance(BucketIcon)(() => ({
  root: {
    marginRight: 'auto',
    marginLeft: 'auto',
    marginBottom: -10,
  },
}));

const Backdrop = variance(ModalContainer)(theme => ({
  root: {
    backgroundColor: theme.chroma('#000000').alpha(0.7).hex(),
  },
}));

const CardHeader = variance(View)(theme => ({
  root: {
    display: 'flex',
    padding: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    marginBottom: 20,
    borderBottomColor: theme.palette.uiSecondary,
  },
}));

const CardHeaderText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 18,
    lineHeight: 22,
  },
}));

const Card = variance(View)(theme => ({
  root: {
    borderRadius: 8,
    backgroundColor: theme.palette.background,
    width: 380,
    paddingBottom: 20,
  },
}));

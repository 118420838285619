import {useRoot, useTheme, variance} from '@ncwallet-app/core';
import {
  CenteredCardLayout,
  SafeAreaInset,
  SafeAreaLayout,
  useIsDimensions,
} from '@ncwallet-app/ui';
import {ImpactFeedbackStyle} from 'expo-haptics';
import {LinearGradient} from 'expo-linear-gradient';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo, useState} from 'react';

import {useGlobalWebNumberInput} from '../../Navigation/hooks';
import {FULL_PIN_LENGTH} from './constants';
import {PinForm} from './PinForm';
import PinKeyboard from './PinKeyboard';
import {PinScreenHeader} from './PinScreenHeader';
import type {PinScreenTexts} from './PinScreenTexts';

export type PinScreenProps = {
  appVersion: string;
  getPin: () => string;
  setPin: (value: string) => void;
  getErrorShown?: () => boolean;
  getErrorText?: string;
  texts: PinScreenTexts;
  onCancel: () => void;
  onBiometryPress?: () => void;
  onErrorActionPress?: () => void;
};

export default observer(function PinScreen(props: PinScreenProps) {
  const {getPin, setPin} = props;
  const theme = useTheme();
  const {haptics} = useRoot();
  const [currentKey, setCurrentKey] = useState({value: 0});

  const handleNumberPress = useCallback(
    (n: number) => {
      void haptics.impact(ImpactFeedbackStyle.Light);
      const pin = getPin();
      if (pin.length === FULL_PIN_LENGTH) {
        return;
      }
      setPin(pin.length < FULL_PIN_LENGTH ? `${pin}${n}` : pin);
      setCurrentKey(prev => ({...prev, value: n}));
    },
    [getPin, haptics, setPin],
  );
  const isLg = useIsDimensions('lg');

  const gradientColors = useMemo(
    () =>
      isLg
        ? {
            linearGradient: [
              theme.palette.background,
              theme.palette.background,
            ] as const,
            pinFormGradient: [
              theme.palette.background,
              theme.palette.background,
            ] as const,
          }
        : {
            linearGradient: [
              theme.chroma(theme.palette.primary).alpha(0.3).hex(),
              theme.palette.mainGradientStart,
            ] as const,
            pinFormGradient: [
              theme.palette.mainGradientStart,
              theme.palette.mainGradientEnd,
            ] as const,
          },
    [isLg, theme],
  );

  const handleBackspacePress = useCallback(() => {
    void haptics.impact(ImpactFeedbackStyle.Medium);
    const pin = getPin();
    setPin(pin.substring(0, pin.length - 1));
    setCurrentKey(prev => ({...prev, value: -1}));
  }, [getPin, haptics, setPin]);

  useGlobalWebNumberInput(handleNumberPress, handleBackspacePress);

  const getFilledLength = useCallback(() => getPin().length, [getPin]);

  return (
    <CenteredCardLayout
      fullHeightOnSmallScreen={true}
      appVersion={props.appVersion}
      onBackPress={props.onCancel}>
      <LinearGradient colors={gradientColors.linearGradient}>
        <SafeAreaLayout insets={SafeAreaInset.TOP}>
          <PinScreenHeader
            version={props.appVersion}
            onCancel={props.onCancel}
            backText={props.texts.backText}
          />
        </SafeAreaLayout>
      </LinearGradient>

      <PinFormGradient colors={gradientColors.pinFormGradient}>
        <PinFormContainer insets={SafeAreaInset.BOTTOM}>
          <PinForm
            title={props.texts.title}
            getFilledLength={getFilledLength}
            fullPinLength={FULL_PIN_LENGTH}
            hasError={props.getErrorShown?.() || !!props.getErrorText}
            errorText={props.getErrorText || props.texts.errorText}
            errorAction={props.texts.errorActionText}
            onErrorActionPress={props.onErrorActionPress}
          />

          <PinKeyboard
            currentKey={currentKey}
            onNumberPress={handleNumberPress}
            onBackspacePress={handleBackspacePress}
            onBiometryPress={props.onBiometryPress}
          />
        </PinFormContainer>
      </PinFormGradient>
    </CenteredCardLayout>
  );
});

const PinFormGradient = variance(LinearGradient)(() => ({
  root: {
    flex: 1,
  },
}));

const PinFormContainer = variance(SafeAreaLayout)(() => ({
  root: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

import Hammer from '@egjs/hammerjs';
import type {Disposer} from '@ncwallet-app/core';
import {batchDisposers, useTheme} from '@ncwallet-app/core';
import useGetElement from '@ncwallet-app/core/src/hooks/useGetElement';
import useStableCallback from '@ncwallet-app/core/src/hooks/useStableCallback';
import useObservableRef from '@ncwallet-app/core/src/mobx-react-toolbox/useObservableRef';
import {autorun} from 'mobx';
import {observer} from 'mobx-react-lite';
import React, {useLayoutEffect, useRef} from 'react';
import {Pressable, View} from 'react-native';

import {useIsDimensions} from '../../../util';
import styles, {ACTIVE_OPACITY} from './styles';
import type {TouchableListItemProps} from './TouchableListItemProps';
import {TouchableListItemVariant} from './TouchableListItemProps';

export default observer((props: TouchableListItemProps) => {
  const {children, Icon, iconColor, onPress: _onPress, threshold} = props;

  const onPress = useStableCallback(_onPress);

  const theme = useTheme();

  const Touchable =
    threshold === undefined ? ReactNativeTouchable : HammerTouchable;

  return (
    <Touchable {...props} onPress={onPress}>
      {children}
      {Icon && (
        <View style={styles.iconContainer}>
          <Icon color={iconColor ?? theme.palette.uiAdditional1} />
        </View>
      )}
    </Touchable>
  );
});

const HammerTouchable = observer((props: TouchableListItemProps) => {
  const {
    children,
    style,
    bodyStyle,
    variant = TouchableListItemVariant.Primary,
    disabled = false,
    onPress,
    testID,
    threshold,
  } = props;

  const disabledRef = useRef(disabled);
  disabledRef.current = disabled;

  const theme = useTheme();
  const isLg = useIsDimensions('lg');
  const underlayColor = theme.chroma(theme.palette[variant]).alpha(0.1).hex();

  const rootRef = useObservableRef<View>(null);
  const getRoot = useGetElement(rootRef);

  const bodyRef = useRef<View>(null);
  const getBody = useGetElement(bodyRef);

  const disposerRef = useRef<() => void>();
  useLayoutEffect(
    () =>
      batchDisposers(
        (() => {
          disposerRef.current?.();
        }) as Disposer,
        autorun(() => {
          disposerRef.current?.();

          const _element = getRoot();
          if (!_element) {
            return;
          }
          const element = _element;

          const prevBackgroundColor = element.style.backgroundColor;
          const onMouseEnter = () => {
            element.style.transition = 'background-color 0.1s ease-in-out';
            element.style.backgroundColor = underlayColor;
          };

          const onMouseLeave = () => {
            element.style.backgroundColor = prevBackgroundColor;
          };

          if (!disabled) {
            element.style.cursor = 'pointer';
            if (isLg) {
              element.addEventListener('mouseenter', onMouseEnter);
              element.addEventListener('mouseleave', onMouseLeave);
            }
          }

          const mc = new Hammer.Manager(element, {
            recognizers: [],
            enable: () => !disabledRef.current,
          });

          const tap = new Hammer.Tap({threshold: threshold ?? 5});
          mc.add(tap);
          mc.on('tap', onTap);

          disposerRef.current = () => {
            const options = {capture: true};
            element.removeEventListener('mouseenter', onMouseEnter);
            element.removeEventListener('mouseleave', onMouseLeave);
            document.removeEventListener('click', preventGhostClick, options);
            document.removeEventListener(
              'mousedown',
              preventGhostClick,
              options,
            );
            mc.off('tap', onTap);
            mc.remove(tap);
            mc.destroy();
            disposerRef.current = undefined;
          };

          let latestTap = -Infinity; // milliseconds

          function preventGhostClick(event: MouseEvent) {
            if (event.timeStamp - latestTap <= GHOST_CLICK_DELAY) {
              event.stopImmediatePropagation();
              event.preventDefault();
            }
          }

          function onTap(event: HammerInput) {
            latestTap = event.srcEvent.timeStamp;
            element.style.transition = 'all 0s ease';
            element.style.backgroundColor = underlayColor;
            requestAnimationFrame(() => {
              element.style.transition = 'background-color 400ms ease-out';
              element.style.backgroundColor = theme
                .chroma(underlayColor)
                .alpha(0)
                .hex();
            });

            const body = getBody();
            if (body) {
              body.style.transition = 'all 0s ease';
              body.style.opacity = `${ACTIVE_OPACITY}`;
              requestAnimationFrame(() => {
                body.style.transition = 'opacity 400ms ease-out';
                body.style.opacity = '1';
              });
            }

            const options = {
              once: true,
              capture: true,
              passive: false,
              signal: AbortSignal.timeout(GHOST_CLICK_DELAY),
            };
            document.addEventListener('click', preventGhostClick, options);
            document.addEventListener('mousedown', preventGhostClick, options);

            onPress?.();
          }
        }),
      ),
    [
      disabled,
      getBody,
      getRoot,
      onPress,
      theme,
      underlayColor,
      isLg,
      threshold,
    ],
  );

  return (
    <View
      ref={rootRef}
      style={[style, disabled && styles.disabled]}
      testID={testID}>
      <View ref={bodyRef} style={[styles.body, bodyStyle]}>
        {children}
      </View>
    </View>
  );
});

const GHOST_CLICK_DELAY = 200; // milliseconds

const ReactNativeTouchable = observer((props: TouchableListItemProps) => {
  const {
    children,
    style,
    bodyStyle,
    variant = TouchableListItemVariant.Primary,
    disabled = false,
    onPress,
    testID,
    threshold,
  } = props;

  const theme = useTheme();
  const isLg = useIsDimensions('lg');
  const underlayColor = theme.chroma(theme.palette[variant]).alpha(0.1).hex();

  return (
    <Pressable
      onPress={onPress}
      style={_ => [
        style,
        disabled && styles.disabled,
        !disabled &&
          _.hovered &&
          isLg && {
            backgroundColor: underlayColor,
          },
      ]}
      disabled={disabled}
      testID={testID}
      pressRetentionOffset={threshold}>
      <View style={[styles.body, bodyStyle]}>{children}</View>
    </Pressable>
  );
});

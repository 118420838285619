import {useRoot, useStrings, useTheme, variance} from '@ncwallet-app/core';
import {
  Button,
  ButtonVariant,
  Container,
  TouchableOpacity,
  XL_BREAKPOINT,
} from '@ncwallet-app/ui';
import {
  ExchangeAltSvg,
  HistorySmallSvg,
  NCWalletLogoWebDarkSvg,
  NCWalletLogoWebSvg,
  ReceiveSvg,
  SendSvg,
} from '@ncwallet-app/ui/src/assets/svg/colored';
import {
  BellSvg,
  DebugTabSvg,
  QrSvg,
  RoundShape,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Platform, StyleSheet, Text, View} from 'react-native';
import {isTablet} from 'react-native-device-info';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

import {useIsExchangeAllowed} from '../../../../../../Navigation/hooks/useIsExchangeAllowed';
import {useIsSuspended} from '../../../../../../Navigation/hooks/useIsSuspended';
import {IS_MOBILE_WEB} from '../../constants';
import BurgerBottomView from '../BurgerBottomView';
import BurgerMenuTouchable from '../BurgerMenuTouchable';

export type HeaderFrameProps = {
  tail?: React.ReactNode;
  onWalletsPress?: () => void;
  onReceivePress?: () => void;
  onExchangePress?: () => void;
  onSendPress?: () => void;
  onHistoryPress?: () => void;
  onDebugPress?: () => void;
  areNotificationsVisible?: boolean;
  toggleNotifications?: () => void;
  unreadNotificationsTotal?: number;
  onQrCodePress?: () => void;
};

export default observer(function HeaderFrame(props: HeaderFrameProps) {
  const {
    tail,
    onWalletsPress,
    onReceivePress,
    onExchangePress,
    onSendPress,
    onHistoryPress,
    onDebugPress,
    areNotificationsVisible,
    toggleNotifications,
    unreadNotificationsTotal,
    onQrCodePress,
  } = props;
  const {appearance, debug} = useRoot();
  const theme = useTheme();
  const strings = useStrings();
  const safeArea = useSafeAreaInsets();
  const isSuspended = useIsSuspended();
  const {isExchangeAllowed} = useIsExchangeAllowed();
  return (
    <RootView style={[styles.header, {paddingTop: safeArea.top || 5}]}>
      <ContentView>
        <TouchableOpacity onPress={onWalletsPress} testID="logo">
          {appearance.isDark ? (
            <NCWalletLogoWebSvg />
          ) : (
            <NCWalletLogoWebDarkSvg />
          )}
        </TouchableOpacity>
        <HeaderNavigationView>
          <NavigationButton
            title={strings['bottomTab.receive']}
            onPress={onReceivePress}
            variant={ButtonVariant.SecondaryLight}
            Icon={ReceiveSvg}
            testID="main-tab-receive-btn"
          />
          <SizedView />
          {isExchangeAllowed && (
            <>
              <NavigationButton
                disabled={isSuspended}
                title={strings['bottomTab.exchange']}
                onPress={onExchangePress}
                variant={ButtonVariant.SecondaryLight}
                Icon={ExchangeAltSvg}
                testID="main-tab-exchange-btn"
              />
              <SizedView />
            </>
          )}
          <NavigationButton
            disabled={isSuspended}
            title={strings['bottomTab.send']}
            onPress={onSendPress}
            variant={ButtonVariant.SecondaryLight}
            Icon={SendSvg}
            testID="main-tab-send-btn"
          />
          <SizedView />
          <NavigationButton
            title={strings['bottomTab.history']}
            onPress={onHistoryPress}
            variant={ButtonVariant.SecondaryLight}
            Icon={HistorySmallSvg}
            testID="main-tab-history-btn"
          />
          <SizedView />
          {debug.debugEnabled && (
            <NavigationButton
              title="Debug"
              onPress={onDebugPress}
              variant={ButtonVariant.SecondaryLight}
              Icon={DebugTabSvg}
            />
          )}
        </HeaderNavigationView>
        <HeaderActionsView>
          <NotificationTouchable
            active={areNotificationsVisible}
            onPress={toggleNotifications}>
            <BellSvg color={theme.palette.uiMain} />
            {unreadNotificationsTotal !== undefined &&
              unreadNotificationsTotal > 0 && (
                <NotificationCountContainer>
                  <NotificationCountText>
                    {unreadNotificationsTotal}
                  </NotificationCountText>
                </NotificationCountContainer>
              )}
            {areNotificationsVisible && (
              <BurgerBottomView>
                <RoundShape
                  color={theme.palette.uiPrimary}
                  style={styles.roundIcon}
                />
              </BurgerBottomView>
            )}
          </NotificationTouchable>
          <SizedView />
          {(Platform.OS !== 'web' || IS_MOBILE_WEB) && (
            <BurgerMenuTouchable onPress={onQrCodePress}>
              <QrSvg color={theme.palette.uiMain} />
            </BurgerMenuTouchable>
          )}
          {tail}
        </HeaderActionsView>
      </ContentView>
    </RootView>
  );
});

const styles = StyleSheet.create({
  header: {
    paddingTop: 5,
    paddingBottom: 5,
    height: isTablet() ? 92 : 72,
  },
  roundIcon: {
    position: 'absolute',
    left: -5,
    bottom: 0,
  },
});

const SizedView = variance(View)(() => ({root: {width: 15}}));

const RootView = variance(View)(theme => ({
  root: {
    backgroundColor: theme.palette.background,
    height: 62,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    ...Platform.select({
      android: {
        borderColor: theme.palette.uiSecondary,
        borderBottomWidth: 1,
        borderStyle: 'solid',
      },
    }),
  },
}));

const ContentView = variance(Container)(() => ({
  root: {
    position: 'relative',
    flexDirection: 'row',
    height: 62,
    alignItems: 'center',
    paddingVertical: 5,
    paddingHorizontal: 15,
  },
}));

const HeaderNavigationView = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    marginRight: 'auto',
    marginLeft: 'auto',
    ...theme.mediaQuery({
      [XL_BREAKPOINT]: {
        marginRight: 120,
        marginLeft: 'auto',
      },
    }),
  },
}));

const NavigationButton = variance(Button)(() => ({
  root: {
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
}));

const HeaderActionsView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const NotificationTouchable = variance(TouchableOpacity)(theme => ({
  root: {
    padding: 15,
  },
  active: {
    borderRadius: 10,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: theme.palette.uiPrimary,
  },
}));

const NotificationCountContainer = variance(View)(theme => ({
  root: {
    position: 'absolute',
    width: 20,
    height: 15,
    top: 15,
    left: 30,
    borderRadius: 2,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.error,
  },
}));

const NotificationCountText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('bold'),
    color: theme.palette.whitey,
    fontSize: 10,
  },
}));

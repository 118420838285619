import {useStrings, variance} from '@ncwallet-app/core';
import {CurrencyCircleLogo, TouchableListItem} from '@ncwallet-app/ui';
import {
  ArrowRightSvg,
  WarningSvg,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

import WalletLimitProgress from './WalletLimitPeriods';
import type {WalletLimitsItemData} from './WalletLimitsItemData';

export type WalletLimitItemProps = {
  data: WalletLimitsItemData;
  onPress: () => void;
};

export default observer(function WalletLimitItem(props: WalletLimitItemProps) {
  const {data} = props;
  const strings = useStrings();

  return (
    <WalletLimitItemContainer onPress={props.onPress}>
      <BodyView>
        <WalletLimitItemTop>
          <CurrencyCircleLogoView>
            <CurrencyCircleLogo code={data.currency.code} size={22} />
          </CurrencyCircleLogoView>
          <WalletLimitCurrencyName>
            {data.currency.name}
          </WalletLimitCurrencyName>
          <WalletLimitItemTopRight>
            {data.hasChanged && (
              <>
                <WarningIcon />
                <WalletLimitHasChangeLabel>
                  {strings['limitsSettingsScreen.limitChanged']}
                </WalletLimitHasChangeLabel>
              </>
            )}
            <ArrowRightIcon />
          </WalletLimitItemTopRight>
        </WalletLimitItemTop>
        <WalletLimitItemBody>
          <WalletLimitProgress
            currency={data.currency}
            dailyLimit={data.dailyLimit}
            monthlyLimit={data.monthlyLimit}
          />
        </WalletLimitItemBody>
      </BodyView>
    </WalletLimitItemContainer>
  );
});

const WalletLimitItemContainer = variance(TouchableListItem)(() => ({
  root: {},
}));

const BodyView = variance(View)(() => ({
  root: {
    flex: 1,
    padding: 15,
  },
}));

const WalletLimitItemTop = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const CurrencyCircleLogoView = variance(View)(() => ({
  root: {
    marginRight: 10,
  },
}));

const ArrowRightIcon = variance(ArrowRightSvg)(
  () => ({
    root: {
      marginLeft: 18,
    },
  }),
  theme => ({
    color: theme.palette.uiAdditional1,
  }),
);

const WarningIcon = variance(WarningSvg)(
  () => ({
    root: {},
  }),
  theme => ({
    width: 13,
    height: 13,
    color: theme.palette.error,
  }),
);

const WalletLimitHasChangeLabel = variance(Text)(theme => ({
  root: {
    marginLeft: 5,
    ...theme.fontByWeight('500'),
    color: theme.palette.error,
    fontSize: 12,
    lineHeight: 15,
  },
}));

const WalletLimitItemTopRight = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 'auto',
  },
}));

const WalletLimitCurrencyName = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 16,
    lineHeight: 20,
    color: theme.palette.textPrimary,
  },
}));

const WalletLimitItemBody = variance(View)(() => ({
  root: {
    marginTop: 15,
  },
}));

import type {AuthScreenFooterProps} from '@ncwallet-app/core/src/Components/AuthScreenFooter';
import type {ButtonPortalProps} from '@ncwallet-app/core/src/Components/ButtonPortal';
import {ButtonPortal} from '@ncwallet-app/core/src/Components/ButtonPortal';
import type {
  ContentLayoutProps,
  ScrollToEndRefType,
} from '@ncwallet-app/core/src/Components/ContentLayout';
import {ContentLayout} from '@ncwallet-app/core/src/Components/ContentLayout';
import type {LgProfileEmailButtonProps} from '@ncwallet-app/core/src/Components/LgProfileEmailButton';
import type {LogButtonProps} from '@ncwallet-app/core/src/Components/LogButton';
import type {PlatformSpecific} from '@ncwallet-app/core/src/Components/PlatformSpecificComponents';
import type {ProfileEmailButtonProps} from '@ncwallet-app/core/src/Components/ProfileEmailButton';
import type {AnimatedBottomTabBarProps} from '@ncwallet-app/navigation/src/components';
import type {DebugScreenProps} from '@ncwallet-app/navigation/src/screens/DebugScreen';
import type {DetailsTelegramButtonProps} from '@ncwallet-app/navigation/src/screens/ProfileSettingsScreen/LgProfileCards/DetailsTelegramButton';
import DetailsTelegramButton from '@ncwallet-app/navigation/src/screens/ProfileSettingsScreen/LgProfileCards/DetailsTelegramButton';
import type {ProfileTelegramButtonProps} from '@ncwallet-app/navigation/src/screens/ProfileSettingsScreen/ProfileTelegramButton';
import {ProfileTelegramButton} from '@ncwallet-app/navigation/src/screens/ProfileSettingsScreen/ProfileTelegramButton';
import type {QrCodeScreenProps} from '@ncwallet-app/navigation/src/screens/QrCodeScreen';
import React, {forwardRef} from 'react';

import {AnimatedBottomTabBar} from '../AnimatedBottomTabBar';
import {DebugScreen} from '../DebugScreen';
import {LgProfileEmailButton} from '../LgProfileEmailButton';
import {LogButton} from '../LogButton';
import {ProfileEmailButton} from '../ProfileEmailButton';
import {TelegramQrCodeScreen} from '../QrCodeScreen';
import TelegramAuthScreenFooter from '../TelegramAuthScreenFooter/TelegramAuthScreenFooter';

export default class TelegramPlatformSpecificComponents
  implements PlatformSpecific
{
  AuthScreenFooter = (props: AuthScreenFooterProps) => (
    <TelegramAuthScreenFooter {...props} />
  );

  ProfileTelegramButton = (props: ProfileTelegramButtonProps) => (
    <ProfileTelegramButton {...props} />
  );

  ProfileEmailButton = (props: ProfileEmailButtonProps) => (
    <ProfileEmailButton {...props} />
  );

  LgProfileEmailButton = (props: LgProfileEmailButtonProps) => (
    <LgProfileEmailButton {...props} />
  );

  DebugScreen = (props: DebugScreenProps) => {
    return <DebugScreen {...props} />;
  };

  LogButton = (props: LogButtonProps) => {
    return <LogButton {...props} />;
  };

  AnimatedBottomTabBar = (props: AnimatedBottomTabBarProps) => {
    return <AnimatedBottomTabBar {...props} />;
  };

  QrCodeScreen = (props: QrCodeScreenProps) => {
    return <TelegramQrCodeScreen {...props} />;
  };

  DetailsTelegramButton = (props: DetailsTelegramButtonProps) => {
    return <DetailsTelegramButton {...props} />;
  };

  ContentLayout = forwardRef<ScrollToEndRefType, ContentLayoutProps>(
    (props, ref) => {
      return <ContentLayout {...props} ref={ref} />;
    },
  );

  ButtonPortal = (props: ButtonPortalProps) => {
    return (
      <ButtonPortal portalKey={props.portalKey}>{props.children}</ButtonPortal>
    );
  };
}

import {
  getAddIpFaqLink,
  urlLinks,
  useStrings,
  useTheme,
} from '@ncwallet-app/core';
import {IpType} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpType';
import type {SmallBottomTabParamList} from '@ncwallet-app/core/src/SmallNavigationScheme/SmallHomeStack/SmallBottomTab/SmallBottomTabParamList';
import type {SmallHomeStackParamList} from '@ncwallet-app/core/src/SmallNavigationScheme/SmallHomeStack/SmallHomeStackParamList';
import type {LocaleDict} from '@ncwallet-app/localization/locale/LocaleStrings';
import {CurrencyCircleLogo, TouchableOpacity} from '@ncwallet-app/ui';
import {
  ExchangeAltSvg,
  ReceiveSvg,
  SendSvg,
} from '@ncwallet-app/ui/src/assets/svg/colored';
import {
  AffiliateUserSVG,
  ArrowLeftSvg,
  BellSvg,
  CrossSvg,
  FilterSvg,
  GearSvg,
  GlobeSvg,
  HelpSvg,
  HistorySvg,
  LimitsSvg,
  LinkSvg,
  SecureSvg,
  UserSvg,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import type {NavigationProp, ParamListBase} from '@react-navigation/native';
import type {StackNavigationOptions} from '@react-navigation/stack';
import {createStackNavigator, TransitionPresets} from '@react-navigation/stack';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {Linking, Platform} from 'react-native';

import ListLogsContainer from '../../CommonNavigationContainers/containers/ListLogsContainer';
import ListSignOutLogsContainer from '../../CommonNavigationContainers/containers/ListSignOutLogsContainer';
import PromptWalletGroupToFilterHistoryContainer from '../../CommonNavigationContainers/containers/PromptWalletGroupToFilterHistoryContainer';
import CurrencyCreateLimitNavigationBar from '../../components/CurrencyCreateLimitNavigationBar';
import CurrencyLimitNavigationBar from '../../components/CurrencyShowLimitNavigationBar';
import NavigationBar, {
  BackButton,
  createNavigationBar,
} from '../../components/NavigationBar';
import TwoFaSettingsNavigationBar from '../../components/TwoFaSettingsNavigationBar';
import {ANIMATION_ENABLED} from '../../Navigation/constants';
import useNavigationSafeAreaCardStyles from '../../Navigation/hooks/useNavigationSafeAreaCardStyles';
import EnvironmentFormScreen from '../../screens/DebugScreen/EnvironmentFormScreen';
import EnvironmentListScreen from '../../screens/DebugScreen/EnvironmentListScreen';
import type {DocumentKind} from '../../screens/ListDocumentsScreen/DocumentButton';
import {getDocumentStrings} from '../../screens/ListDocumentsScreen/DocumentButton';
import CheckBiometricsPinBinding from './bindings/CheckBiometricsPinBinding';
import CheckPinBinding from './bindings/CheckPinBinding';
import FallBackBinding from './bindings/FallBackBinding';
import GeneratePayMeLinkBinding from './bindings/GeneratePayMeLinkBinding';
import IdentityVerificationUploadDocumentBinding from './bindings/IdentityVerificationUploadDocumentBinding';
import ListActiveSessionsBinding from './bindings/ListActiveSessionsBinding';
import ListAllowedIpsBinding from './bindings/ListAllowedIpsBinding';
import ListBlockedIpsBinding from './bindings/ListBlockedIpsBinding';
import ListDocumentsBinding from './bindings/ListDocumentsBinding';
import ListHistoryFiltersBinding from './bindings/ListHistoryFiltersBinding';
import ListInputAddressesBinding from './bindings/ListInputAddressesBinding';
import ListLimitsBinding from './bindings/ListLimitsBinding';
import ListMenuCategoriesBinding from './bindings/ListMenuCategoriesBinding';
import ListNotificationsBinding from './bindings/ListNotificationsBinding';
import ListNotificationSettingsBinding from './bindings/ListNotificationSettingsBinding';
import ListPersonalDataBinding from './bindings/ListPersonalDataBinding';
import ListQrCodeHistoryBinding from './bindings/ListQrCodeHistoryBinding';
import ListSecuritySettingsBinding from './bindings/ListSecuritySettingsBinding';
import ListSessionHistoryBinding from './bindings/ListSessionHistoryBinding';
import {ListWalletFilterOptionsBinding} from './bindings/ListWalletFilterOptionsBinding';
import NotifyAboutSuccessfulExchangeBinding from './bindings/NotifyAboutSuccessfulExchangeBinding';
import NotifyAboutSuccessfulSendingBinding from './bindings/NotifyAboutSuccessfulSendingBinding';
import PromptAccountDeletionBinding from './bindings/PromptAccountDeletionBinding';
import PromptAddIpBinding from './bindings/PromptAddIpBinding';
import PromptAddressFormatBinding from './bindings/PromptAddressFormatBinding';
import PromptAffiliateProgramBinding from './bindings/PromptAffiliateProgramBinding';
import PromptAmountToSendBinding from './bindings/PromptAmountToSendBinding';
import PromptBaseCryptoBinding from './bindings/PromptBaseCryptoBinding';
import PromptBaseFiatBinding from './bindings/PromptBaseFiatBinding';
import PromptBiometricSettingsBinding from './bindings/PromptBiometricSettingsBinding';
import PromptBuyCryptoBinding from './bindings/PromptBuyCryptoBinding';
import PromptCloseAppBinding from './bindings/PromptCloseAppBinding';
import PromptCommentToSendBinding from './bindings/PromptCommentToSendBinding';
import PromptCommissionForSendingBinding from './bindings/PromptCommissionForSendingBinding';
import PromptConfirmationForExchangeBinding from './bindings/PromptConfirmationForExchangeBinding';
import PromptConfirmationToSendCryptoBinding from './bindings/PromptConfirmationToSendCryptoBinding';
import PromptCryptoToExchangeBinding from './bindings/PromptCryptoToExchangeBinding';
import PromptDateOfHistoryPeriodBinding from './bindings/PromptDateOfHistoryPeriodBinding';
import PromptDocumentPermissionBinding from './bindings/PromptDocumentPermissionBinding';
import PromptEmailBinding from './bindings/PromptEmailBinding';
import PromptExchangeReceiptBinding from './bindings/PromptExchangeReceiptBinding';
import PromptFeedbackBinding from './bindings/PromptFeedbackBinding';
import PromptInputNetworkBinding from './bindings/PromptInputNetworkBinding';
import PromptLanguageBinding from './bindings/PromptLanguageBinding';
import PromptLimitRemovalBinding from './bindings/PromptLimitRemovalBinding';
import PromptLimitUpdateBinding from './bindings/PromptLimitUpdateBinding';
import PromptLinkToEmailBinding from './bindings/PromptLinkToEmailBinding';
import PromptLinkToTelegramBinding from './bindings/PromptLinkToTelegramBinding';
import PromptNameAndBirthdateBinding from './bindings/PromptNameAndBirthdateBinding';
import PromptNewLimitBinding from './bindings/PromptNewLimitBinding';
import PromptNewPinBinding from './bindings/PromptNewPinBinding';
import PromptNewWalletBinding from './bindings/PromptNewWalletBinding';
import PromptNicknameBinding from './bindings/PromptNicknameBinding';
import PromptOtpToAddIpBinding from './bindings/PromptOtpToAddIpBinding';
import PromptOtpToBiometricsBinding from './bindings/PromptOtpToBiometricsBinding';
import PromptOtpToChangeTwoFaSettingsBinding from './bindings/PromptOtpToChangeTwoFaSettingsBinding';
import PromptOtpToClearAllowedListBinding from './bindings/PromptOtpToClearAllowedListBinding';
import PromptOtpToDeleteAccountBinding from './bindings/PromptOtpToDeleteAccountBinding';
import PromptOtpToDeleteIpBinding from './bindings/PromptOtpToDeleteIpBinding';
import PromptOtpToDisableTwoFaBinding from './bindings/PromptOtpToDisableTwoFaBinding';
import PromptOtpToEnableTwoFaBinding from './bindings/PromptOtpToEnableTwoFaBinding';
import PromptOtpToExchangeBinding from './bindings/PromptOtpToExchangeBinding';
import PromptOtpToLimitCreateBinding from './bindings/PromptOtpToLimitCreateBinding';
import PromptOtpToLimitRemovalBinding from './bindings/PromptOtpToLimitRemovalBinding';
import PromptOtpToLimitUpdateBinding from './bindings/PromptOtpToLimitUpdateBinding';
import PromptOtpToSendCryptoBinding from './bindings/PromptOtpToSendCryptoBinding';
import PromptOtpToEditIpBinding from './bindings/PromptOtpToUpdateIpBinding';
import PromptOutputAddressBinding from './bindings/PromptOutputAddressBinding';
import PromptOutputNetworkBinding from './bindings/PromptOutputNetworkBinding';
import PromptPeriodToFilterHistoryBinding from './bindings/PromptPeriodToFilterHistoryBinding';
import PromptRateBinding from './bindings/PromptRateBinding';
import PromptReceiveNetworkBinding from './bindings/PromptReceiveNetworkBinding';
import PromptResidentialAddressBinding from './bindings/PromptResidentialAddressBinding';
import PromptSelectTwoFaProviderBinding from './bindings/PromptSelectTwoFaProviderBinding';
import PromptSignOutBinding from './bindings/PromptSignOutBinding';
import PromptThemeBinding from './bindings/PromptThemeBinding';
import PromptToChooseIdentityFileBinding from './bindings/PromptToChooseIdentityFileBinding';
import PromptToClearAllowedListBinding from './bindings/PromptToClearAllowedListBinding';
import PromptToDeleteDocumentFileBinding from './bindings/PromptToDeleteDocumentFileBinding';
import PromptToDeleteIpBinding from './bindings/PromptToDeleteIpBinding';
import PromptToRemoveSessionBottomSheetBinding from './bindings/PromptToRemoveSessionBottomSheetBinding';
import PromptTransactionKindToFilterHistoryBinding from './bindings/PromptTransactionKindToFilterHistoryBinding';
import PromptAllowedIpBinding from './bindings/PromptUpdateIpBinding';
import PromptUserIdBinding from './bindings/PromptUserIdBinding';
import PromptWalletToFilterHistoryBinding from './bindings/PromptWalletToFilterHistoryBinding';
import PromptToSelectVerificationCountryBinding from './bindings/PromtpToSelectVerificationCountryBinding';
import RedirectToSendBinding from './bindings/RedirectToSendBinding';
import ShowDocumentCameraBinding from './bindings/ShowDocumentCameraBinding';
import ShowGeneratedSecretBinding from './bindings/ShowGeneratedSecretBinding';
import ShowLimitBinding from './bindings/ShowLimitBinding';
import ShowProfileBinding from './bindings/ShowProfileBinding';
import ShowQrBinding from './bindings/ShowQrBinding';
import ShowQrCodeScannerBinding from './bindings/ShowQrCodeScannerBinding';
import ShowQrToReceiveCryptoBinding from './bindings/ShowQrToReceiveCryptoBinding';
import ShowTransactionBinding from './bindings/ShowTransactionBinding';
import ShowTransactionReportBinding from './bindings/ShowTransactionReportBinding';
import ShowTwoFaStatusBinding from './bindings/ShowTwoFaStatusBinding';
import ShowWalletBinding from './bindings/ShowWalletBinding';
import SmallBottomTabsBinding from './bindings/SmallBottomTabs/SmallBottomTabsBinding';
import TwoFaWelcomeBinding from './bindings/TwoFaWelcomeBinding';
import type {SmallHomeStackBindingProps} from './SmallHomeStackBindingProps';
import {useHandleBack} from './useHandleBack';
import {goToSmallHomeStackTab} from './util/goToSmallHomeStackTab';

export default observer(function SmallHomeStack() {
  const theme = useTheme();
  const strings = useStrings();
  useHandleBack();
  const handleFaqPress = useCallback(async (link: string) => {
    await Linking.openURL(link);
  }, []);

  const safeAreaCardStyles = useNavigationSafeAreaCardStyles();

  return (
    <Navigator
      screenOptions={{
        headerShown: false,
        animationEnabled: ANIMATION_ENABLED,
        cardStyle: {
          flex: 1,
        },
        ...(Platform.OS === 'android'
          ? TransitionPresets.ModalSlideFromBottomIOS
          : TransitionPresets.DefaultTransition),
      }}
      initialRouteName="Root">
      <Screen
        name="FallBack"
        options={{headerShown: false, title: strings['screenBaseTitle.home']}}
        component={FallBackBinding}
      />

      <Screen name="Root" component={SmallBottomTabsBinding} />

      <Screen
        name="ListHistoryFilters"
        component={ListHistoryFiltersBinding}
        options={{
          headerShown: true,
          title: strings['screenBaseTitle.history'],
          header: ({navigation}) => {
            const handleGoBack = () => {
              goBackOrFallbackToTab('ListHistory', navigation);
            };
            return (
              <NavigationBar
                title={strings['screenTitle.filters']}
                titleIcon={<FilterSvg color={theme.palette.primary} />}
                leftButton={
                  <BackButton Icon={ArrowLeftSvg} onPress={handleGoBack} />
                }
              />
            );
          },
        }}
      />
      <Screen
        name="PromptWalletGroupToFilterHistory"
        component={PromptWalletGroupToFilterHistoryContainer}
        options={{
          headerShown: true,
          title: strings['screenBaseTitle.history'],
          header: createNavigationBar({
            screenTitle: strings['screenTitle.filters'],
            titleIcon: <FilterSvg color={theme.palette.primary} />,
            withBackButton: true,
          }),
        }}
      />
      <Screen
        name="PromptWalletToFilterHistory"
        component={PromptWalletToFilterHistoryBinding}
        options={{
          headerShown: true,
          title: strings['screenBaseTitle.history'],
          header: createNavigationBar<SmallHomeStackParamList>({
            screenTitle: strings['screenTitle.filters'],
            titleIcon: <FilterSvg color={theme.palette.primary} />,
            withBackButton: true,
            withoutBorder: true,
            onEmptyHistoryBack: navigation => {
              navigation.replace('ListHistoryFilters');
            },
          }),
        }}
      />
      <Screen
        name="PromptTransactionKindToFilterHistory"
        component={PromptTransactionKindToFilterHistoryBinding}
        options={{
          headerShown: true,
          title: strings['screenBaseTitle.history'],
          header: createNavigationBar<SmallHomeStackParamList>({
            screenTitle: strings['screenTitle.filters'],
            titleIcon: <FilterSvg color={theme.palette.primary} />,
            withBackButton: true,
            onEmptyHistoryBack: navigation => {
              navigation.replace('ListHistoryFilters');
            },
          }),
        }}
      />
      <Screen
        name="ShowTransaction"
        component={ShowTransactionBinding}
        options={{
          headerShown: true,
          title: strings['screenBaseTitle.history'],
          header: ({navigation}) => {
            const handleGoBack = () => {
              goBackOrFallbackToTab('ListHistory', navigation);
            };
            return (
              <NavigationBar
                title={strings['screenTitle.historyDetails']}
                leftButton={
                  <BackButton Icon={ArrowLeftSvg} onPress={handleGoBack} />
                }
              />
            );
          },
        }}
      />
      <Screen
        name="ShowTransactionReport"
        component={ShowTransactionReportBinding}
        options={{
          ...TransitionPresets.ModalFadeTransition,
          cardStyle: useNavigationSafeAreaCardStyles(),
          headerShown: false,
          presentation: 'transparentModal',
        }}
      />
      <Screen
        name="PromptPeriodToFilterHistory"
        component={PromptPeriodToFilterHistoryBinding}
        options={{
          headerShown: true,
          title: strings['screenBaseTitle.history'],
          header: createNavigationBar<SmallHomeStackParamList>({
            screenTitle: strings['screenTitle.filters'],
            titleIcon: <FilterSvg color={theme.palette.primary} />,
            withBackButton: true,
            onEmptyHistoryBack: navigation => {
              navigation.replace('ListHistoryFilters');
            },
          }),
        }}
      />
      <Screen
        name="PromptFeedback"
        component={PromptFeedbackBinding}
        options={{
          headerShown: true,
          title: strings['feedbackModalScreen.header'],
          header: createNavigationBar({
            withBackButton: true,
          }),
        }}
      />

      <Screen
        name="PromptThemeSelect"
        component={PromptThemeBinding}
        options={{
          ...TransitionPresets.ModalFadeTransition,
          headerShown: false,
          title: strings['screenBaseTitle.menu'],
          presentation: 'transparentModal',
        }}
      />

      <Screen
        name="PromptRate"
        component={PromptRateBinding}
        options={{
          ...TransitionPresets.ModalFadeTransition,
          headerShown: false,
          title: strings['screenBaseTitle.menu'],
          presentation: 'transparentModal',
        }}
      />

      <Screen
        name="PromptDateOfHistoryPeriod"
        component={PromptDateOfHistoryPeriodBinding}
        options={{
          ...TransitionPresets.ModalFadeTransition,
          headerShown: false,
          title: strings['screenTitle.calendar'],
          presentation: 'transparentModal',
        }}
      />

      <Screen
        name="PromptOutputAddress"
        component={PromptOutputAddressBinding}
        options={{
          headerShown: true,
          headerMode: 'screen',
          title: strings['screenBaseTitle.sendCrypto'],
          header: createNavigationBar({
            screenTitle: strings['screenTitle.sendCrypto'],
            titleIcon: <SendSvg color={theme.palette.textMain} />,
            onEmptyHistoryBack: navigation => {
              goToSmallHomeStackTab('PromptCryptoToSend', navigation);
            },
            withBackButton: true,
          }),
        }}
      />

      <Screen
        name="PromptOutputNetwork"
        component={PromptOutputNetworkBinding}
        options={{
          headerShown: true,
          headerMode: 'screen',
          title: strings['screenBaseTitle.sendCrypto'],
          header: createNavigationBar({
            screenTitle: strings['screenTitle.sendCrypto'],
            titleIcon: <SendSvg color={theme.palette.textMain} />,
            onEmptyHistoryBack: navigation => {
              goToSmallHomeStackTab('PromptCryptoToSend', navigation);
            },
            withBackButton: true,
          }),
        }}
      />

      <Screen
        name="CheckPin"
        component={CheckPinBinding}
        options={{
          headerShown: false,
          title: strings['screenTitle.checkYourPin'],
        }}
      />

      <Screen
        name="CheckBiometricsPin"
        component={CheckBiometricsPinBinding}
        options={{
          headerShown: false,
          title: strings['screenTitle.checkYourPin'],
        }}
      />

      <Screen
        name="PromptOtpToBiometrics"
        component={PromptOtpToBiometricsBinding}
        options={{
          headerShown: true,
          title: strings['screenTitle.biometric'],
          header: createNavigationBar({
            withBackButton: true,
            onEmptyHistoryBack: navigation => {
              navigation.replace('PromptBiometricSettings');
            },
          }),
        }}
      />

      <Screen
        name="PromptBiometricSettings"
        component={PromptBiometricSettingsBinding}
        options={{
          headerShown: true,
          title: strings['screenTitle.biometric'],
          header: createNavigationBar<SmallHomeStackParamList>({
            screenTitle: strings['screenTitle.biometric'],
            withBackButton: true,
            onEmptyHistoryBack: navigation => {
              navigation.replace('ListSecuritySettings');
            },
          }),
        }}
      />

      <Screen
        name="PromptNewPin"
        component={PromptNewPinBinding}
        options={{headerShown: false, title: strings['screenTitle.updatePin']}}
      />

      <Screen
        name="ListSecuritySettings"
        component={ListSecuritySettingsBinding}
        options={{
          headerShown: true,
          title: strings['screenBaseTitle.security'],
          header: createNavigationBar<SmallHomeStackParamList>({
            screenTitle: strings['screenTitle.secure'],
            titleIcon: <SecureSvg color={theme.palette.primary} />,
            withBackButton: true,
            onEmptyHistoryBack: navigation => {
              navigation.replace('Root');
            },
          }),
        }}
      />

      <Screen
        name="ListMenuCategories"
        component={ListMenuCategoriesBinding}
        options={{
          headerShown: false,
          title: strings['screenBaseTitle.menu'],
          animationEnabled: ANIMATION_ENABLED,
        }}
      />

      <Group
        screenOptions={{
          animationEnabled: ANIMATION_ENABLED,
          headerShown: true,
        }}>
        <Screen
          name="ListLimits"
          component={ListLimitsBinding}
          options={{
            title: strings['screenTitle.limitsSettings'],
            header: createNavigationBar({
              titleIcon: <LimitsSvg color={theme.palette.primary} />,
              withBackButton: true,
              withoutBorder: true,
              onEmptyHistoryBack: navigation => {
                navigation.replace('ListMenuCategories');
              },
            }),
          }}
        />
        <Screen
          name="PromptLanguage"
          component={PromptLanguageBinding}
          options={{
            title: strings['screenTitle.languageSettings'],
            header: createNavigationBar<SmallHomeStackParamList>({
              titleIcon: <GlobeSvg color={theme.palette.primary} />,
              withBackButton: true,
              onEmptyHistoryBack: navigation => {
                navigation.replace('ListMenuCategories');
              },
            }),
          }}
        />

        <Screen
          name="PromptNewLimit"
          component={PromptNewLimitBinding}
          options={{
            title: strings['screenTitle.limitsSettings'],
            header: props => <CurrencyCreateLimitNavigationBar {...props} />,
          }}
        />

        <Screen
          name="PromptOtpToLimitCreate"
          component={PromptOtpToLimitCreateBinding}
          options={{
            title: strings['screenTitle.limitsSettings'],
            header: createNavigationBar({
              withBackButton: true,
              onEmptyHistoryBack: navigation => {
                navigation.replace('ListMenuCategories');
              },
            }),
          }}
        />

        <Screen
          name="PromptLimitUpdate"
          component={PromptLimitUpdateBinding}
          options={{
            title: strings['screenTitle.limitsSettings'],
            header: createNavigationBar({
              withBackButton: true,
              onEmptyHistoryBack: navigation => {
                navigation.replace('ListMenuCategories');
              },
            }),
          }}
        />

        <Screen
          name="PromptOtpToLimitUpdate"
          component={PromptOtpToLimitUpdateBinding}
          options={{
            title: strings['screenTitle.limitsSettings'],
            header: createNavigationBar({
              withBackButton: true,
              onEmptyHistoryBack: navigation => {
                navigation.replace('ListMenuCategories');
              },
            }),
          }}
        />

        <Screen
          name="PromptOtpToLimitRemoval"
          component={PromptOtpToLimitRemovalBinding}
          options={{
            title: strings['screenTitle.limitsSettings'],
            header: createNavigationBar({
              withBackButton: true,
              onEmptyHistoryBack: navigation => {
                navigation.replace('ListMenuCategories');
              },
            }),
          }}
        />

        <Screen
          name="ShowLimit"
          component={ShowLimitBinding}
          options={{
            title: strings['screenTitle.limitsSettings'],
            header: props => <CurrencyLimitNavigationBar {...props} />,
          }}
        />

        <Screen
          name="PromptLimitRemoval"
          component={PromptLimitRemovalBinding}
          options={{
            ...TransitionPresets.ModalFadeTransition,
            title: strings['screenTitle.limitsSettings'],
            headerShown: false,
            presentation: 'transparentModal',
          }}
        />

        <Screen
          name="ShowProfile"
          component={ShowProfileBinding}
          options={{
            title: strings['screenBaseTitle.profile'],
            header: createNavigationBar<SmallHomeStackParamList>({
              screenTitle: strings['screenTitle.profile'],
              titleIcon: <UserSvg color={theme.palette.primary} />,
              withBackButton: true,
              onEmptyHistoryBack: navigation => {
                navigation.replace('Root');
              },
            }),
          }}
        />

        <Screen
          name="PromptAffiliateProgram"
          component={PromptAffiliateProgramBinding}
          options={{
            title: strings['userSettings.affiliate'],
            header: createNavigationBar<SmallHomeStackParamList>({
              screenTitle: strings['userSettings.affiliate'],
              titleIcon: <AffiliateUserSVG color={theme.palette.uiMain} />,
              withBackButton: true,
              onEmptyHistoryBack: navigation => {
                navigation.replace('Root');
              },
            }),
          }}
        />

        <Screen
          name="PromptNickname"
          component={PromptNicknameBinding}
          options={{
            title: strings['screenBaseTitle.profile'],
            header: createNavigationBar<SmallHomeStackParamList>({
              screenTitle: strings['screenTitle.updateNickname'],
              withBackButton: true,
              onEmptyHistoryBack: navigation => {
                navigation.replace('Root');
              },
            }),
          }}
        />
        <Screen
          name="PromptUserId"
          component={PromptUserIdBinding}
          options={{
            title: strings['screenBaseTitle.profile'],
            header: createNavigationBar({
              screenTitle: strings['screenTitle.updateUserId'],
              withBackButton: true,
              onEmptyHistoryBack: navigation => {
                navigation.replace('ListMenuCategories');
              },
            }),
          }}
        />
        <Screen
          name="PromptEmail"
          component={PromptEmailBinding}
          options={{
            title: strings['screenBaseTitle.profile'],
            header: createNavigationBar({
              screenTitle: strings['screenTitle.updateEmail'],
              withBackButton: true,
              onEmptyHistoryBack: navigation => {
                navigation.replace('ListMenuCategories');
              },
            }),
          }}
        />
        <Screen
          name="ListPersonalData"
          component={ListPersonalDataBinding}
          options={{
            title: strings['screenTitle.identityVerification'],
            header: createNavigationBar({
              withBackButton: true,
              onEmptyHistoryBack: navigation => {
                navigation.replace('ListMenuCategories');
              },
            }),
          }}
        />
        <Screen
          name="PromptNameAndBirthdate"
          component={PromptNameAndBirthdateBinding}
          options={{
            title: strings['screenTitle.identityInfo'],
            header: createNavigationBar({
              withBackButton: true,
              onEmptyHistoryBack: navigation => {
                navigation.replace('ListMenuCategories');
              },
            }),
          }}
        />
        <Screen
          name="PromptResidentialAddress"
          component={PromptResidentialAddressBinding}
          options={{
            title: strings['screenTitle.identityAddress'],
            header: createNavigationBar({
              withBackButton: true,
              onEmptyHistoryBack: navigation => {
                navigation.replace('ListMenuCategories');
              },
            }),
          }}
        />
        <Screen
          name="PromptIdentityVerificationAddress"
          component={PromptToSelectVerificationCountryBinding}
          options={{
            title: strings['screenTitle.identityCountry'],
            header: createNavigationBar({
              withBackButton: true,
              onEmptyHistoryBack: navigation => {
                navigation.replace('ListMenuCategories');
              },
            }),
          }}
        />
        <Screen
          name="ListDocuments"
          component={ListDocumentsBinding}
          options={{
            title: strings['screenTitle.identityDocuments'],
            header: createNavigationBar({
              withBackButton: true,
              onEmptyHistoryBack: navigation => {
                navigation.replace('ListMenuCategories');
              },
            }),
          }}
        />
        <Screen
          name="PromptToChooseIdentityFile"
          component={PromptToChooseIdentityFileBinding}
          options={props => ({
            ...TransitionPresets.ModalFadeTransition,
            title: getUploadDocumentTitle(
              props.route.params.type as DocumentKind,
              strings,
            ),
            cardStyle: safeAreaCardStyles,
            headerShown: false,
            presentation: 'transparentModal',
          })}
        />
        <Screen
          name="IdentityVerificationUploadDocument"
          component={IdentityVerificationUploadDocumentBinding}
          options={props => ({
            title: getUploadDocumentTitle(
              props.route.params.type as DocumentKind,
              strings,
            ),
            header: createNavigationBar({
              withBackButton: true,
              onEmptyHistoryBack: navigation => {
                navigation.replace('ListMenuCategories');
              },
            }),
          })}
        />
        <Screen
          name="PromptToDeleteDocumentFile"
          component={PromptToDeleteDocumentFileBinding}
          options={{
            ...TransitionPresets.ModalFadeTransition,
            title: strings['deleteIdentityUploadedDocument.title'],
            cardStyle: useNavigationSafeAreaCardStyles(),
            headerShown: false,
            presentation: 'transparentModal',
          }}
        />
        <Screen
          name="ShowDocumentCamera"
          component={ShowDocumentCameraBinding}
          options={{
            ...TransitionPresets.ModalFadeTransition,
            headerShown: false,
            title: strings['screenBaseTitle.profile'],
            presentation: 'transparentModal',
          }}
        />
        <Screen
          name="PromptDocumentPermission"
          component={PromptDocumentPermissionBinding}
          options={{
            ...TransitionPresets.ModalFadeTransition,
            headerShown: false,
            title: strings['screenBaseTitle.profile'],
            presentation: 'transparentModal',
          }}
        />
        <Screen
          name="PromptBaseCryptoSetting"
          component={PromptBaseCryptoBinding}
          options={{
            title: strings['screenBaseTitle.profile'],
            header: createNavigationBar<SmallHomeStackParamList>({
              screenTitle: strings['screenTitle.selectBaseCrypt'],
              withBackButton: true,
              withoutBorder: true,
              withLargeTitle: true,
              onEmptyHistoryBack: navigation => {
                navigation.replace('Root');
              },
            }),
          }}
        />
        <Screen
          name="PromptBaseFiatSetting"
          component={PromptBaseFiatBinding}
          options={{
            title: strings['screenBaseTitle.profile'],
            header: createNavigationBar<SmallHomeStackParamList>({
              screenTitle: strings['screenTitle.selectBaseFiat'],
              withBackButton: true,
              withoutBorder: true,
              onEmptyHistoryBack: navigation => {
                navigation.replace('Root');
              },
            }),
          }}
        />

        <Screen
          name="ListNotificationSettings"
          component={ListNotificationSettingsBinding}
          options={{
            cardStyle: useNavigationSafeAreaCardStyles(),
            headerShown: true,
            title: strings['screenBaseTitle.notifications'],
            header: createNavigationBar<SmallHomeStackParamList>({
              screenTitle: strings['screenTitle.notifications'],
              titleIcon: <BellSvg color={theme.palette.primary} />,
              withBackButton: true,
              onEmptyHistoryBack: navigation => {
                navigation.replace('Root');
              },
            }),
          }}
        />

        <Screen
          name="ListSessionHistory"
          component={ListSessionHistoryBinding}
          options={{
            cardStyle: useNavigationSafeAreaCardStyles(),
            title: strings['screenBaseTitle.security'],
            header: createNavigationBar({
              screenTitle: strings['screenTitle.sessionsHistory'],
              withBackButton: true,
              onEmptyHistoryBack: navigation => {
                navigation.replace('ListMenuCategories');
              },
            }),
          }}
        />

        <Screen
          name="ListActiveSessions"
          component={ListActiveSessionsBinding}
          options={{
            cardStyle: useNavigationSafeAreaCardStyles(),
            title: strings['screenBaseTitle.security'],
            header: createNavigationBar({
              screenTitle: strings['screenTitle.activeSessions'],
              withBackButton: true,
              onEmptyHistoryBack: navigation => {
                navigation.replace('ListMenuCategories');
              },
            }),
          }}
        />

        <Screen
          name="PromptSessionDeletion"
          component={PromptToRemoveSessionBottomSheetBinding}
          options={{
            ...TransitionPresets.ModalFadeTransition,
            cardStyle: useNavigationSafeAreaCardStyles(),
            headerShown: false,
            presentation: 'transparentModal',
          }}
        />

        <Screen
          name="ListAllowedIp"
          component={ListAllowedIpsBinding}
          options={{
            title: strings['screenTitle.ipAllowList'],
            header: createNavigationBar({
              withBackButton: true,
              rightButton: (
                <TouchableOpacity
                  onPress={() => handleFaqPress(urlLinks.ipListsFaq)}>
                  <HelpSvg color={theme.palette.uiAdditional1} />
                </TouchableOpacity>
              ),
              onEmptyHistoryBack: navigation => {
                navigation.replace('ListMenuCategories');
              },
            }),
          }}
        />

        <Screen
          name="PromptAddIp"
          component={PromptAddIpBinding}
          options={props => ({
            title: getIpListTitle(props.route.params.type, strings),
            header: createNavigationBar({
              withBackButton: true,
              rightButton: (
                <TouchableOpacity
                  onPress={() =>
                    handleFaqPress(getAddIpFaqLink(props.route.params.type))
                  }>
                  <HelpSvg color={theme.palette.uiAdditional1} />
                </TouchableOpacity>
              ),
              onEmptyHistoryBack: navigation => {
                navigation.replace(
                  props.route.params.type === IpType.Allowed
                    ? 'ListAllowedIp'
                    : 'ListBlockedIp',
                );
              },
            }),
          })}
        />
        <Screen
          name="ListBlockedIp"
          component={ListBlockedIpsBinding}
          options={{
            title: strings['screenTitle.ipBlockList'],
            header: createNavigationBar({
              withBackButton: true,
              rightButton: (
                <TouchableOpacity
                  onPress={() => handleFaqPress(urlLinks.ipListsFaq)}>
                  <HelpSvg color={theme.palette.uiAdditional1} />
                </TouchableOpacity>
              ),
              onEmptyHistoryBack: navigation => {
                navigation.replace('ListMenuCategories');
              },
            }),
          }}
        />

        <Screen
          name="PromptUpdateIp"
          component={PromptAllowedIpBinding}
          options={props => ({
            title: getIpListTitle(props.route.params.type, strings),
            header: PromptUpdateIpNavigationBar,
          })}
        />
        <Screen
          name="PromptToDeleteIp"
          component={PromptToDeleteIpBinding}
          options={props => ({
            ...TransitionPresets.ModalFadeTransition,
            headerShown: false,
            presentation: 'transparentModal',
            title: getIpListTitle(props.route.params.type, strings),
          })}
        />
        <Screen
          name="PromptToClearAllowedList"
          component={PromptToClearAllowedListBinding}
          options={() => ({
            ...TransitionPresets.ModalFadeTransition,
            title: strings['screenTitle.ipBlockList'],
            headerShown: false,
            presentation: 'transparentModal',
          })}
        />

        <Screen
          name="PromptOtpToClearAllowedList"
          component={PromptOtpToClearAllowedListBinding}
          options={{
            title: strings['screenTitle.ipBlockList'],
            header: createNavigationBar({
              withBackButton: true,
              onEmptyHistoryBack: navigation => {
                navigation.replace('ListSecuritySettings');
              },
            }),
          }}
        />

        <Screen
          name="PromptOtpToDeleteIp"
          component={PromptOtpToDeleteIpBinding}
          options={props => ({
            title: strings['screenTitle.ipBlockList'],
            header: createNavigationBar({
              withBackButton: true,
              title: getIpListTitle(props.route.params.type, strings),
              onEmptyHistoryBack: navigation => {
                navigation.replace('ListSecuritySettings');
              },
            }),
          })}
        />

        <Screen
          name="PromptOtpToAddIp"
          component={PromptOtpToAddIpBinding}
          options={props => ({
            header: createNavigationBar({
              withBackButton: true,
              title: getIpListTitle(props.route.params.type, strings),
              onEmptyHistoryBack: navigation => {
                navigation.replace('ListSecuritySettings');
              },
            }),
          })}
        />

        <Screen
          name="PromptOtpToUpdateIp"
          component={PromptOtpToEditIpBinding}
          options={props => ({
            header: createNavigationBar({
              withBackButton: true,
              title: getIpListTitle(props.route.params.type, strings),
              onEmptyHistoryBack: navigation => {
                navigation.replace('ListSecuritySettings');
              },
            }),
          })}
        />

        <Screen
          name="ShowTwoFaGenerateSecret"
          component={ShowGeneratedSecretBinding}
          options={{
            title: strings['screenBaseTitle.security'],
            header: createNavigationBar<SmallHomeStackParamList>({
              screenTitle: strings['secureTwoFactorAuthScreen.enableButton'],
              withBackButton: true,
              onEmptyHistoryBack: navigation => {
                navigation.replace('ListSecuritySettings');
              },
            }),
          }}
        />

        <Screen
          name="TwoFaWelcome"
          component={TwoFaWelcomeBinding}
          options={{
            title: strings['screenBaseTitle.security'],
            header: createNavigationBar<SmallHomeStackParamList>({
              screenTitle: strings['secureTwoFactorAuthScreen.enableButton'],
              withBackButton: true,
              onEmptyHistoryBack: navigation => {
                navigation.replace('ListSecuritySettings');
              },
            }),
          }}
        />

        <Screen
          name="PromptOtpToEnableTwoFa"
          component={PromptOtpToEnableTwoFaBinding}
          options={{
            title: strings['screenBaseTitle.security'],
            header: createNavigationBar({
              screenTitle: strings['secureTwoFactorAuthScreen.enableButton'],
              withBackButton: true,
              onEmptyHistoryBack: navigation => {
                navigation.replace('ListMenuCategories');
              },
            }),
          }}
        />
        <Screen
          name="ShowTwoFaSettings"
          component={ShowTwoFaStatusBinding}
          options={{
            title: strings['screenBaseTitle.security'],
            header: props => <TwoFaSettingsNavigationBar {...props} />,
          }}
        />
        <Screen
          name="PromptOtpToDisableTwoFa"
          component={PromptOtpToDisableTwoFaBinding}
          options={{
            title: strings['screenBaseTitle.security'],
            header: createNavigationBar({
              screenTitle: strings['screenTitle.disableTwoFactorAuth'],
              withBackButton: true,
              onEmptyHistoryBack: navigation => {
                navigation.replace('ListMenuCategories');
              },
            }),
          }}
        />

        <Screen
          name="PromptOtpToChangeTwoFaSettings"
          component={PromptOtpToChangeTwoFaSettingsBinding}
          options={{
            title: strings['screenBaseTitle.security'],
            header: createNavigationBar({
              screenTitle: strings['screenTitle.promptOtpToChange2FASettings'],
              withBackButton: true,
              onEmptyHistoryBack: navigation => {
                navigation.replace('ListMenuCategories');
              },
            }),
          }}
        />
      </Group>

      <Group
        screenOptions={{
          presentation: 'card',
          headerMode: 'screen',
          headerShown: true,
          title: strings['screenBaseTitle.sendCrypto'],
          header: createNavigationBar({
            screenTitle: strings['screenTitle.sendCrypto'],
            titleIcon: <SendSvg color={theme.palette.textMain} />,
            onEmptyHistoryBack: navigation => {
              navigation.navigate('Root');
            },
            withBackButton: true,
          }),
        }}>
        <Screen
          name="PromptAmountToSend"
          component={PromptAmountToSendBinding}
        />
        <Screen
          name="PromptCommentToSend"
          component={PromptCommentToSendBinding}
        />
        <Screen
          name="PromptCommissionForSending"
          component={PromptCommissionForSendingBinding}
        />

        <Screen
          name="PromptConfirmationToSendCrypto"
          component={PromptConfirmationToSendCryptoBinding}
          options={{
            headerShown: true,
            title: strings['screenBaseTitle.sendCrypto'],
            header: createNavigationBar({
              screenTitle: strings['screenTitle.sendConfirm'],
              withBackButton: true,
              onEmptyHistoryBack: navigation => {
                goToSmallHomeStackTab('PromptCryptoToSend', navigation);
              },
            }),
          }}
        />

        <Screen
          name="PromptOtpToSendCrypto"
          component={PromptOtpToSendCryptoBinding}
          options={{
            headerShown: true,
            title: strings['screenBaseTitle.sendCrypto'],
            header: createNavigationBar({
              screenTitle: strings['screenTitle.confirmCode'],
              withBackButton: true,
              onEmptyHistoryBack: navigation => {
                goToSmallHomeStackTab('PromptCryptoToSend', navigation);
              },
            }),
          }}
        />

        <Screen
          name="NotifyAboutSuccessfulSending"
          component={NotifyAboutSuccessfulSendingBinding}
          options={{
            title: strings['screenTitle.sendSuccess'],
            header: createNavigationBar(),
          }}
        />
      </Group>

      <Group
        screenOptions={{
          headerShown: true,
          presentation: 'card',
          headerMode: 'screen',
        }}>
        <Screen
          name="ListQrCodeHistory"
          component={ListQrCodeHistoryBinding}
          options={{
            title: `${strings['qrCodeScreen.showHistory']} | NC Wallet`,
            header: createNavigationBar({
              screenTitle: strings['qrCodeScreen.showHistory'],
              titleIcon: (
                <HistorySvg
                  color={theme.palette.info}
                  secondColor={theme.palette.uiSecondary}
                />
              ),
              withBackButton: true,
              onEmptyHistoryBack: navigation => {
                navigation.replace('Root');
              },
            }),
          }}
        />
        <Screen
          name="ShowWallet"
          component={ShowWalletBinding}
          options={(
            props: SmallHomeStackBindingProps<'ShowWallet'>,
          ): StackNavigationOptions => {
            const code = props.route.params.currencyCode;

            return {
              title: `${strings['screenTitle.wallets']} | NC Wallet`,
              header: createNavigationBar<SmallHomeStackParamList>({
                screenTitle: code,
                titleIcon: <CurrencyCircleLogo code={code} size={22} />,
                withBackButton: true,
                onEmptyHistoryBack: navigation => {
                  navigation.replace('Root');
                },
              }),
            };
          }}
        />
        <Screen
          name="PromptNewWallet"
          component={PromptNewWalletBinding}
          options={{
            title: `${strings['currencySelection.addCoin']} | NC Wallet`,
            header: createNavigationBar<SmallHomeStackParamList>({
              screenTitle: strings['currencySelection.addCoin'],
              withBackButton: true,
              withoutBorder: true,
              onEmptyHistoryBack: navigation => {
                navigation.replace('Root');
              },
            }),
          }}
        />

        <Screen
          name="PromptBuyCrypto"
          component={PromptBuyCryptoBinding}
          options={{
            title: strings['buyCrypto.title'],
            header: createNavigationBar<SmallHomeStackParamList>({
              screenTitle: strings['buyCrypto.title'],
              withBackButton: true,
              withoutBorder: true,
              onEmptyHistoryBack: navigation => {
                navigation.replace('Root');
              },
            }),
          }}
        />

        <Screen
          name="PromptCryptoToExchange"
          component={PromptCryptoToExchangeBinding}
          options={{
            title: strings['screenBaseTitle.exchange'],
            header: createNavigationBar({
              screenTitle: strings['screenTitle.selectCoin'],
              withBackButton: true,
              withoutBorder: true,
            }),
          }}
        />

        <Screen
          name="PromptExchangeReceipt"
          component={PromptExchangeReceiptBinding}
          options={{
            title: strings['screenBaseTitle.exchange'],
            header: createNavigationBar<SmallHomeStackParamList>({
              screenTitle: strings['screenTitle.change'],
              titleIcon: <ExchangeAltSvg color={theme.palette.textMain} />,
              withBackButton: true,
              onEmptyHistoryBack: navigation => {
                goToSmallHomeStackTab(
                  'PromptSourceCryptoToExchange',
                  navigation,
                );
              },
            }),
          }}
        />

        <Screen
          name="PromptConfirmationForExchange"
          component={PromptConfirmationForExchangeBinding}
          options={{
            title: strings['screenBaseTitle.exchange'],
            header: createNavigationBar<SmallHomeStackParamList>({
              screenTitle: strings['screenTitle.exchangeConfirm'],
              withBackButton: true,
              onEmptyHistoryBack: navigation => {
                goToSmallHomeStackTab(
                  'PromptSourceCryptoToExchange',
                  navigation,
                );
              },
            }),
          }}
        />

        <Screen
          name="PromptOtpToExchange"
          component={PromptOtpToExchangeBinding}
          options={{
            title: strings['screenBaseTitle.exchange'],
            header: createNavigationBar<SmallHomeStackParamList>({
              screenTitle: strings['screenTitle.exchangeConfirm'],
              withBackButton: true,
              onEmptyHistoryBack: navigation => {
                goToSmallHomeStackTab(
                  'PromptSourceCryptoToExchange',
                  navigation,
                );
              },
            }),
          }}
        />

        <Screen
          name="NotifyAboutSuccessfulExchange"
          component={NotifyAboutSuccessfulExchangeBinding}
          options={{
            title: strings['screenBaseTitle.exchange'],
            header: createNavigationBar({
              screenTitle:
                strings['userSettings.notifications.exchange.exchangeSuccess'],
            }),
          }}
        />

        <Screen
          name="ShowQrToReceiveCrypto"
          component={ShowQrToReceiveCryptoBinding}
          options={{
            title: strings['screenBaseTitle.receive'],
            header: createNavigationBar({
              screenTitle: strings['screenTitle.receive'],
              titleIcon: <ReceiveSvg color={theme.palette.textMain} />,
              withBackButton: true,
              onEmptyHistoryBack: navigation => {
                goToSmallHomeStackTab('PromptCryptoToReceive', navigation);
              },
            }),
          }}
        />
        <Screen
          name="ListInputAddresses"
          component={ListInputAddressesBinding}
          options={{
            title: strings['screenBaseTitle.receive'],
            header: ({navigation, options}) => {
              const handleGoBack = () => {
                if (navigation.canGoBack()) {
                  navigation.goBack();
                  return;
                }
                navigation.replace('Root');
              };
              return (
                <NavigationBar
                  {...options}
                  title={strings['screenTitle.receive']}
                  titleIcon={<LinkSvg color={theme.palette.info} />}
                  leftButton={<BackButton onPress={handleGoBack} />}
                />
              );
            },
          }}
        />
        <Screen
          name="PromptAddressFormat"
          component={PromptAddressFormatBinding}
          options={{
            ...TransitionPresets.ModalFadeTransition,
            headerShown: false,
            title: strings['screenBaseTitle.profile'],
            presentation: 'transparentModal',
          }}
        />

        <Screen
          name="PromptReceiveNetwork"
          component={PromptReceiveNetworkBinding}
          options={{
            ...TransitionPresets.ModalFadeTransition,
            headerShown: false,
            title: strings['screenBaseTitle.profile'],
            presentation: 'transparentModal',
          }}
        />

        <Screen
          name="ShowQr"
          component={ShowQrBinding}
          options={{
            ...TransitionPresets.ModalFadeTransition,
            headerShown: false,
            title: strings['screenBaseTitle.profile'],
            presentation: 'transparentModal',
          }}
        />

        <Screen
          name="GeneratePayMeLink"
          component={GeneratePayMeLinkBinding}
          options={{
            title: strings['generatePayMeLink.screenTitle'],
            header: createNavigationBar({
              screenTitle: strings['generatePayMeLink.screenTitle'],
              withBackButton: true,
              onEmptyHistoryBack: navigation => {
                goToSmallHomeStackTab('PromptCryptoToReceive', navigation);
              },
            }),
          }}
        />

        <Screen
          name="PromptInputNetwork"
          component={PromptInputNetworkBinding}
          options={{
            title: strings['screenBaseTitle.receive'],
            header: createNavigationBar({
              screenTitle: strings['screenTitle.addresses'],
              titleIcon: <LinkSvg color={theme.palette.info} />,
              withBackButton: true,
              onEmptyHistoryBack: navigation => {
                goToSmallHomeStackTab('PromptCryptoToReceive', navigation);
              },
            }),
          }}
        />

        <Screen
          name="ScanQrCodeScanner"
          component={ShowQrCodeScannerBinding}
          options={{
            title: `${strings['screenTitle.qrCode']} | NC Wallet`,
            cardOverlayEnabled: Platform.OS === 'ios',
            headerShown: false,
            animationEnabled: ANIMATION_ENABLED,
          }}
        />

        <Screen
          name="PromptLinkToEmail"
          component={PromptLinkToEmailBinding}
          options={{
            ...TransitionPresets.ModalFadeTransition,
            headerShown: false,
            title: strings['linkToEmail.title'],
            presentation: 'transparentModal',
          }}
        />

        <Screen
          name="PromptLinkToTelegram"
          component={PromptLinkToTelegramBinding}
          options={{
            ...TransitionPresets.ModalFadeTransition,
            headerShown: false,
            title: strings['linkToTelegram.title'],
            presentation: 'transparentModal',
          }}
        />

        <Screen
          name="PromptAccountDeletion"
          component={PromptAccountDeletionBinding}
          options={{
            ...TransitionPresets.ModalFadeTransition,
            headerShown: false,
            title: strings['screenBaseTitle.profile'],
            presentation: 'transparentModal',
          }}
        />

        <Screen
          name="PromptSignOut"
          component={PromptSignOutBinding}
          options={{
            ...TransitionPresets.ModalFadeTransition,
            headerShown: false,
            title: strings['screenBaseTitle.profile'],
            presentation: 'transparentModal',
          }}
        />

        <Screen
          name="PromptCloseApp"
          component={PromptCloseAppBinding}
          options={{
            ...TransitionPresets.ModalFadeTransition,
            headerShown: false,
            presentation: 'transparentModal',
          }}
        />

        <Screen
          name="PromptSelectTwoFaProvider"
          component={PromptSelectTwoFaProviderBinding}
          options={{
            ...TransitionPresets.ModalFadeTransition,
            headerShown: false,
            title: strings['screenBaseTitle.profile'],
            presentation: 'transparentModal',
          }}
        />

        <Screen
          name="PromptOtpToDeleteAccount"
          component={PromptOtpToDeleteAccountBinding}
          options={{
            title: strings['screenBaseTitle.profile'],
            header: createNavigationBar({
              title: strings['screenTitle.deleteAccount'],
              withBackButton: true,
              onEmptyHistoryBack: navigation => {
                navigation.replace('Root');
              },
            }),
          }}
        />
        <Screen
          name="ListNotifications"
          component={ListNotificationsBinding}
          options={{
            cardStyle: useNavigationSafeAreaCardStyles(),
            title: strings['screenBaseTitle.notifications'],
            header: ({navigation}) => {
              const handleGoBack = () => {
                if (navigation.canGoBack()) {
                  navigation.goBack();
                  return;
                }
                navigation.replace('Root');
              };
              return (
                <NavigationBar
                  title={strings['screenTitle.notifications']}
                  titleIcon={<BellSvg color={theme.palette.primary} />}
                  leftButton={
                    <BackButton Icon={CrossSvg} onPress={handleGoBack} />
                  }
                  rightButton={
                    <BackButton
                      Icon={GearSvg}
                      onPress={() => {
                        navigation.navigate('ListNotificationSettings');
                      }}
                    />
                  }
                />
              );
            },
          }}
        />
      </Group>

      <Screen
        name={'ListWalletOptions'}
        component={ListWalletFilterOptionsBinding}
        options={{
          ...TransitionPresets.ModalFadeTransition,
          headerShown: false,
          title: strings['linkToEmail.title'],
          presentation: 'transparentModal',
        }}
      />

      {/*  DEBUG ONLY SCREENS*/}
      <Screen
        name="ListLogs"
        component={ListLogsContainer}
        options={{
          title: 'Logs',
          headerShown: true,
          headerBackTitleVisible: false,
        }}
      />
      <Screen
        name="ListSignOutReasonLogs"
        component={ListSignOutLogsContainer}
        options={{
          title: 'Logs',
          headerShown: true,
          headerBackTitleVisible: false,
        }}
      />

      <Screen
        name="ListEnvironment"
        options={{
          title: 'Environment List',
          headerShown: true,
          headerBackTitleVisible: false,
        }}
        component={EnvironmentListScreen}
      />
      <Screen
        name="PromptEnvironmentForm"
        component={EnvironmentFormScreen}
        options={{
          title: 'Environment Form',
          headerShown: true,
          headerBackTitleVisible: false,
        }}
      />
      <Screen
        name="RedirectToSend"
        options={{headerShown: false}}
        component={RedirectToSendBinding}
      />
    </Navigator>
  );
});

const {Navigator, Screen, Group} =
  createStackNavigator<SmallHomeStackParamList>();

function goBackOrFallbackToTab(
  name: keyof SmallBottomTabParamList,
  navigation: NavigationProp<ParamListBase>,
) {
  if (navigation.canGoBack()) {
    navigation.goBack();
    return;
  }
  goToSmallHomeStackTab(name, navigation);
}

const PromptUpdateIpNavigationBar = createNavigationBar({
  withBackButton: true,
  onEmptyHistoryBack: navigation => {
    navigation.replace('ListMenuCategories');
  },
});

const getIpListTitle = (ipType: IpType, strings: LocaleDict) =>
  ipType === IpType.Allowed
    ? strings['screenTitle.ipAllowList']
    : strings['screenTitle.ipBlockList'];

const getUploadDocumentTitle = (
  documentType: DocumentKind,
  strings: LocaleDict,
) => {
  return getDocumentStrings(strings)[documentType];
};

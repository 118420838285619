import type {BottomSheetMethods} from '@gorhom/bottom-sheet/src/types';
import {useStrings, variance} from '@ncwallet-app/core';
import {BottomSheet, Button, ButtonVariant} from '@ncwallet-app/ui';
import {
  CameraSvg,
  DotsHorizontalSvg,
  FolderSvg,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useRef} from 'react';
import {Platform, Text, View} from 'react-native';

import {IS_MOBILE_WEB} from '../../LargeNavigationRoot/LargeHomeStack/bindings/LargeSwitch/constants';

export type ChoosePhotoBottomSheetProps = {
  onClose: () => void;
  onCamera: () => void;
  onUploadPhoto: () => void;
  onUploadFile: () => void;
};

export default observer(function PromptToChooseIdentityFileScreen(
  props: ChoosePhotoBottomSheetProps,
) {
  const {onUploadPhoto, onUploadFile, onCamera, onClose} = props;
  const isMobile = Platform.OS !== 'web' || IS_MOBILE_WEB;
  const strings = useStrings();
  const ref = useRef<BottomSheetMethods>(null);

  // isConfirmedRef и все что с ним связано - костыль над миганием
  // боттом шита при открытии следующего скрина
  const isConfirmedRef = useRef(false);

  const onChange = useCallback(
    (index: number) => {
      if (index === -1 && !isConfirmedRef.current) {
        onClose();
      }
    },
    [onClose],
  );

  const handleCameraPress = useCallback(() => {
    isConfirmedRef.current = true;
    ref.current?.close();
    onClose();
    onCamera();
  }, [onClose, onCamera]);

  return (
    <BottomSheet
      ref={ref}
      index={0}
      animateOnMount
      onClose={onClose}
      onChange={onChange}
      backdropProps={{appearsOnIndex: 0, disappearsOnIndex: -1}}
      snapPoints={isMobile ? [320] : [180]}
      enablePanDownToClose
      title={strings['profileSettingsScreen.verification.choose.title']}>
      <BottomSheetTop>
        <ChoosePhotoText>
          {strings['verification.upload.photo']}
        </ChoosePhotoText>
      </BottomSheetTop>
      <ChoosePhotoActions>
        {isMobile && (
          <>
            <Button
              title={strings['profileSettingsScreen.verification.choose.photo']}
              variant={ButtonVariant.ThemeButton}
              Icon={CameraSvg}
              onPress={handleCameraPress}
            />
            <SizedBox />
            <Button
              title={
                strings['profileSettingsScreen.verification.choose.library']
              }
              variant={ButtonVariant.ThemeButton}
              Icon={FolderSvg}
              onPress={onUploadPhoto}
            />
            <SizedBox />
          </>
        )}
        <Button
          title={strings['profileSettingsScreen.verification.choose.browse']}
          variant={ButtonVariant.ThemeButton}
          Icon={DotsHorizontalSvg}
          onPress={onUploadFile}
        />
      </ChoosePhotoActions>
    </BottomSheet>
  );
});

const BottomSheetTop = variance(View)(() => ({
  root: {
    paddingVertical: 20,
    paddingHorizontal: 15,
  },
}));

const ChoosePhotoText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    color: theme.palette.textAdditional1,
  },
}));

const ChoosePhotoActions = variance(View)(() => ({
  root: {
    paddingHorizontal: 15,
  },
}));

const SizedBox = variance(View)(() => ({
  root: {
    height: 20,
  },
}));

import type {WalletListItemData} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import {Platform} from 'react-native';
import type {SharedValue} from 'react-native-reanimated';

import WalletListItemSwipeContainer from '../WalletListItemSwipeContainer';
import {BaseWalletListItem} from './BaseWalletListItem';
import HideAction from './HideAction';
import NativeHideAction from './HideAction.native';

export type WalletListItemProps = {
  data: WalletListItemData;
  onPress: () => void;
  hideWallet: () => void;
};

export default observer(function WalletListItem(props: WalletListItemProps) {
  const {hideWallet, data, onPress} = props;
  const cryptoCurrency = data.getCryptoCurrency();
  const RightAction = useMemo(
    () => (progress?: SharedValue<number>) => {
      if (Platform.OS === 'web') {
        return <HideAction onPress={hideWallet} />;
      }
      return progress ? (
        <NativeHideAction progress={progress} onPress={hideWallet} />
      ) : null;
    },
    [hideWallet],
  );
  return (
    <WalletListItemSwipeContainer
      canChange={cryptoCurrency?.canChange ?? false}
      value={data.cryptoValue}
      renderRight={RightAction}
      threshold={THRESHOLD}>
      <BaseWalletListItem {...props} onPress={onPress} threshold={THRESHOLD} />
    </WalletListItemSwipeContainer>
  );
});

const THRESHOLD = 5;

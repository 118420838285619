import type {Account} from '@ncwallet-app/core/src';
import {useRoot, useStrings, useTheme, variance} from '@ncwallet-app/core/src';
import {
  ButtonVariant,
  Input,
  InputType,
  TouchableOpacity,
} from '@ncwallet-app/ui';
import {CopySvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {setStringAsync} from 'expo-clipboard';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Text, View} from 'react-native';
import {useThrottledCallback} from 'use-debounce';

import {
  BodyTag,
  CardButtonTag,
  HeaderTag,
  HeaderTitleTag,
  LabelTag,
  LgProfileCardContainer,
} from './useCardStyles';

export type DetailsCardProps = {
  userInfo: Account | undefined;
  onUserSelect: (name: string | undefined, id: string | undefined) => void;
  onInteraction: () => void;
  onDeleteAccountPress: () => void;
  onLinkToTelegramPress: () => void;
  onLinkToEmailPress: () => void;
};

export default observer(function DetailsCard(props: DetailsCardProps) {
  const {
    userInfo,
    onUserSelect,
    onInteraction,
    onDeleteAccountPress,
    onLinkToTelegramPress,
    onLinkToEmailPress,
  } = props;
  const strings = useStrings();
  const [name, setName] = useState(userInfo?.name);
  const [idText, setIdText] = useState(userInfo?.user_id);
  const theme = useTheme();
  const {flashMessage, platformSpecificComponents} = useRoot();
  const {DetailsTelegramButton, LgProfileEmailButton} =
    platformSpecificComponents;
  const DEBOUNCE_INTERVAL = 3000;
  const handleCopyId = useCallback(() => {
    void setStringAsync(idText || '');
    flashMessage.showMessage({
      title: strings.copied,
      variant: 'success',
    });
  }, [flashMessage, idText, strings.copied]);
  useEffect(() => {
    if (userInfo !== undefined) {
      setIdText(userInfo.user_id);
      setName(userInfo.name);
    }
  }, [userInfo]);
  const copyIdThrottled = useThrottledCallback(
    handleCopyId,
    DEBOUNCE_INTERVAL,
    {
      trailing: false,
    },
  );
  const telegramUsername = useMemo(() => {
    const _telegramUsername = userInfo?.telegram_username;
    return _telegramUsername ? _telegramUsername : '-';
  }, [userInfo?.telegram_username]);
  return (
    <LgProfileCardContainer>
      <HeaderTag>
        <HeaderTitleTag>
          {strings['profileSettingsScreen.detailsTitle']}
        </HeaderTitleTag>
      </HeaderTag>
      <StyledBodyTag>
        <Input
          onFocus={onInteraction}
          type={InputType.Email}
          label={strings['profileSettingsScreen.updateNicknameLabel']}
          placeholder={
            strings['profileSettingsScreen.updateNicknamePlaceholder']
          }
          value={name}
          onChangeText={setName}
        />
        <StyledLabelTag>
          {strings['profileSettingsScreen.updateUserIdLabel']}
        </StyledLabelTag>
        <DisabledValue>
          <DisabledText>{idText}</DisabledText>
          <TouchableOpacity onPress={copyIdThrottled}>
            <CopySvg color={theme.palette.foreground} />
          </TouchableOpacity>
        </DisabledValue>
        <DetailsTelegramButton
          onLinkToTelegramPress={onLinkToTelegramPress}
          telegramUsername={telegramUsername}
        />
        <StyledLabelTag>
          {strings['profileSettingsScreen.emailLabel']}
        </StyledLabelTag>
        <LgProfileEmailButton
          testID="remove-account-btn"
          email={userInfo?.email}
          onDeleteAccountPress={onDeleteAccountPress}
          onLinkToEmailPress={onLinkToEmailPress}
        />
        <CardButtonTag
          onFocus={onInteraction}
          title={strings['profileSettingsScreen.saveButton']}
          variant={ButtonVariant.Primary}
          onPress={() => {
            onUserSelect(name, idText);
          }}
        />
      </StyledBodyTag>
    </LgProfileCardContainer>
  );
});

const DisabledValue = variance(View)(theme => ({
  root: {
    padding: 15,
    borderRadius: 6,
    backgroundColor: theme.palette.uiPrimary,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  smallPadding: {
    paddingVertical: 5,
    paddingRight: 5,
  },
}));

const DisabledText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    color: theme.palette.uiMain,
    fontSize: 16,
  },
  error: {
    color: theme.palette.error,
  },
}));

const StyledBodyTag = variance(BodyTag)(() => ({
  root: {
    paddingHorizontal: 20,
    paddingVertical: 16,
  },
}));

const StyledLabelTag = variance(LabelTag)(() => ({
  root: {
    marginTop: 18,
  },
}));

import type {CameraCapturedPicture, Either} from '@ncwallet-app/core';
import {error, success, useRoot} from '@ncwallet-app/core';
import {getDocumentAsync} from 'expo-document-picker';
import {getInfoAsync} from 'expo-file-system';
import {launchImageLibraryAsync} from 'expo-image-picker';
import {useCallback} from 'react';
import {Platform} from 'react-native';

export type VerificationFile = {
  uri: string;
  fileName: string | undefined;
};

const MAX_FILE_SIZE = 10 * 1000000;

export enum VerificationFileError {
  TOO_LARGE_FILE,
  CANCELLED,
}

export const getVerificationFileFromFs = async (): Promise<
  Either<VerificationFile, VerificationFileError>
> => {
  const file = await getDocumentAsync({
    copyToCacheDirectory: true,
    multiple: false,
    type: [
      'image/png',
      'image/jpeg',
      'image/gif',
      'image/bmp',
      'application/pdf',
    ],
  });

  const _file = file.assets?.[0];
  if (file.canceled || !_file || !_file.uri) {
    return error(VerificationFileError.CANCELLED);
  }

  const fileSize = _file.size ?? (await getFileSize(_file.uri));
  if (fileSize > MAX_FILE_SIZE) {
    return error(VerificationFileError.TOO_LARGE_FILE);
  }

  return success({uri: _file.uri, fileName: _file.name});
};

export const getVerificationFileFromImageLibrary = async (): Promise<
  Either<VerificationFile, VerificationFileError>
> => {
  const libraryRes = await launchImageLibraryAsync({
    mediaTypes: ['images'],
    allowsEditing: false,
    aspect: [4, 3],
    quality: 1,
  });

  if (libraryRes.canceled) {
    return error(VerificationFileError.CANCELLED);
  }

  const file = libraryRes.assets[0];
  const fileSize = file.fileSize ?? (await getFileSize(file.uri));
  if (fileSize > MAX_FILE_SIZE) {
    return error(VerificationFileError.TOO_LARGE_FILE);
  }

  return success({
    uri: file.uri,
    fileName: file.fileName ?? undefined,
  });
};

export const getVerificationFileFromCamera = async (
  file: CameraCapturedPicture | undefined,
): Promise<Either<VerificationFile, VerificationFileError>> => {
  if (!file) {
    return error(VerificationFileError.CANCELLED);
  }

  const fileSize = await getFileSize(file.uri);

  if (fileSize > MAX_FILE_SIZE) {
    return error(VerificationFileError.TOO_LARGE_FILE);
  }
  return success({uri: file.uri, fileName: undefined});
};

export const useHandleVerificationFileError = () => {
  const root = useRoot();

  return useCallback(
    (e: VerificationFileError) => {
      if (e === VerificationFileError.TOO_LARGE_FILE) {
        root.flashMessage.showMessage({title: 'validation.tooLargeFileSize'});
      }
    },
    [root],
  );
};

const getFileSize = async (fileUri: string) => {
  return Platform.OS === 'web'
    ? getFileSizeOnWeb(fileUri)
    : getFileSizeOnMobile(fileUri);
};

const getFileSizeOnMobile = async (fileUri: string) => {
  const fileInfo = await getInfoAsync(fileUri);
  if (fileInfo.exists) {
    return fileInfo.size;
  } else {
    // should never happen (getFileSize for validation only called after selecting image files by hand)
    return 0;
  }
};

const getFileSizeOnWeb = async (fileUri: string) => {
  // https://stackoverflow.com/a/76438436
  const paddingCount = fileUri.endsWith('==')
    ? 2
    : fileUri.endsWith('=')
      ? 1
      : 0;

  return Math.ceil(fileUri.length / 4) * 3 - paddingCount;
};

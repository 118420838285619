import {useStrings} from '@ncwallet-app/core';
import {variance} from '@ncwallet-app/core/src/styling';
import {Button, ButtonVariant} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import {Text, View} from 'react-native';

import {LabelTag} from './useCardStyles';

export type DetailsTelegramButtonProps = {
  telegramUsername: string;
  onLinkToTelegramPress?: () => void;
};

const DetailsTelegramButton = observer(
  ({telegramUsername, onLinkToTelegramPress}: DetailsTelegramButtonProps) => {
    const strings = useStrings();
    const isAddButtonDisabled = useMemo(() => {
      return telegramUsername !== '-';
    }, [telegramUsername]);

    return (
      <>
        <StyledLabelTag>Telegram</StyledLabelTag>
        <Row>
          <DisabledValue smallPadding>
            <DisabledText numberOfLines={1}>{telegramUsername}</DisabledText>
          </DisabledValue>
          <ButtonStyled
            disabled={isAddButtonDisabled}
            variant={ButtonVariant.Primary}
            title={strings['linkToTelegram.add']}
            onPress={onLinkToTelegramPress}
          />
        </Row>
      </>
    );
  },
);

export default DetailsTelegramButton;

const Row = variance(View)(() => ({
  root: {
    padding: 4,
    flexDirection: 'row',
    gap: 10,
  },
}));

const DisabledValue = variance(View)(theme => ({
  root: {
    flex: 1,
    padding: 15,
    borderRadius: 6,
    backgroundColor: theme.palette.uiPrimary,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 50,
  },
  smallPadding: {
    paddingVertical: 5,
    paddingRight: 5,
  },
}));

const DisabledText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    color: theme.palette.uiMain,
    fontSize: 16,
  },
  error: {
    color: theme.palette.error,
  },
}));

const ButtonStyled = variance(Button)(() => ({
  root: {
    paddingHorizontal: 30,
  },
}));

const StyledLabelTag = variance(LabelTag)(() => ({
  root: {
    marginTop: 18,
  },
}));

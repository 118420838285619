import {variance} from '@ncwallet-app/core';
import {TouchableListItem} from '@ncwallet-app/ui/src';
import type React from 'react';
import {Text, View} from 'react-native';
import type {SvgProps} from 'react-native-svg';

export type SettingsItemProps = {
  testID?: string;
  label?: string;
  Icon?: React.ComponentType<SvgProps>;
  reverse?: boolean;
  getTitle?: () => string | undefined;
  title?: string;
  getError?: () => boolean | undefined;
  error?: boolean;
  success?: boolean;
  warning?: boolean;
  active?: boolean;
  disabled?: boolean;
  selected?: boolean;
  onPress: () => void;
};

export const ItemTouchable = variance(TouchableListItem)(theme => ({
  root: {
    borderBottomWidth: 1,
    borderColor: theme.palette.uiSecondary,
    paddingHorizontal: 15,
    paddingVertical: 20,
  },
  second: {
    paddingVertical: 14,
  },
}));
export const ItemTouchableWithLabel = variance(TouchableListItem)(theme => ({
  root: {
    borderBottomWidth: 1,
    borderColor: theme.palette.uiSecondary,
    paddingHorizontal: 15,
    paddingTop: 15,
    paddingBottom: 17,
  },
  selected: {
    borderColor: theme.palette.additional2,
    backgroundColor: theme.palette.additional2,
  },
}));
export const ItemCol = variance(View)(() => ({
  root: {
    flexDirection: 'column',
  },
  icon: {
    marginLeft: 15,
  },
}));
export const ItemLabel = variance(Text)(theme => ({
  root: {
    fontSize: 13,
    lineHeight: 16,
    color: theme.palette.uiMain,
    ...theme.fontByWeight('600'),
  },
  withBottomMargin: {
    marginTop: 5,
  },
  selected: {
    color: theme.palette.primary,
  },
}));
export const TitleWithLabel = variance(Text)(theme => ({
  root: {
    fontSize: 14,
    lineHeight: 17,
    color: theme.palette.textPrimary,
    marginTop: 5,
    ...theme.fontByWeight('700'),
  },
  error: {
    color: theme.palette.error,
  },
  success: {
    color: theme.palette.success,
  },
  warning: {
    color: theme.palette.primary,
  },

  noMargin: {
    marginTop: 0,
  },
}));
export const ItemTitle = variance(Text)(theme => ({
  root: {
    fontSize: 14,
    lineHeight: 17,
    color: theme.palette.textPrimary,
    ...theme.fontByWeight('700'),
  },
}));

export const CurrencyValue = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    fontSize: 13,
    lineHeight: 16,
    color: theme.palette.textAdditional2,
    marginTop: 5,
  },
}));

import {useStrings, variance} from '@ncwallet-app/core';
import type {
  AccountDocument,
  AccountDocumentType,
} from '@ncwallet-app/core/src/NCWalletServer/AccountsDocuments';
import {AccountDocumentStatus} from '@ncwallet-app/core/src/NCWalletServer/AccountsDocuments';
import type {LocaleDict} from '@ncwallet-app/localization/locale/LocaleStrings';
import type {BaseSafeAreaProps} from '@ncwallet-app/ui/src';
import {Button, ButtonVariant, SafeAreaScrollView} from '@ncwallet-app/ui/src';
import {observer} from 'mobx-react-lite';
import React, {useState} from 'react';
import {Text, View} from 'react-native';

import DocumentButton from './DocumentButton';
import {DocumentButtonBadgeVariant} from './DocumentButtonBadgeVariant';
import TermsCheckbox from './TermsCheckbox';

export type ListDocumentsScreenProps = BaseSafeAreaProps & {
  documentTypes: AccountDocumentType[];
  documents: AccountDocument[];
  onDocumentPress: (documentType: AccountDocumentType) => void;
  onSubmit: () => void;
  onGoBack: () => void;
};

export default observer(function ListDocumentsScreen(
  props: ListDocumentsScreenProps,
) {
  const {
    onDocumentPress,
    insets,
    extra,
    onSubmit,
    onGoBack,
    documentTypes,
    documents,
  } = props;
  const strings = useStrings();
  const [isCheckedTerms, setIsCheckedTerms] = useState(false);

  const onToggleTerms = () => {
    setIsCheckedTerms(prev => !prev);
  };

  return (
    <SafeAreaScrollView insets={insets} extra={extra}>
      <DocumentsTopView>
        <DocumentsTopText>
          {strings['profileSettingsScreen.verification.documents.topText']}
        </DocumentsTopText>
      </DocumentsTopView>
      <DocumentsButtonsView>
        {documentTypes.map(documentType => {
          const onPress = () => {
            onDocumentPress(documentType);
          };
          const document = documents.find(
            (doc: AccountDocument) => doc.type === documentType,
          );
          const badge = getDocumentBadge(document?.status, strings);
          const disabled = !(
            document?.status === undefined ||
            document.status === AccountDocumentStatus.Uploaded
          );
          return (
            <DocumentButton
              key={documentType}
              title={documentType}
              onPress={onPress}
              badge={badge}
              disabled={disabled}
            />
          );
        })}
      </DocumentsButtonsView>
      <BottomView>
        <TermsCheckbox
          label={strings['profileSettingsScreen.verification.documents.term']}
          isChecked={isCheckedTerms}
          onPress={onToggleTerms}
        />
        <SubmitButton
          title={strings['feedbackModalScreen.submitButton']}
          variant={ButtonVariant.Primary}
          onPress={onSubmit}
          disabled={!isCheckedTerms}
        />
        <CancelButton
          title={strings['historyPeriodFilterScreen.datepickerTo.cancel']}
          variant={ButtonVariant.Danger}
          onPress={onGoBack}
        />
      </BottomView>
    </SafeAreaScrollView>
  );
});

export const getDocumentBadge = (
  status: AccountDocumentStatus | undefined,
  strings: LocaleDict,
): {text: string; kind: DocumentButtonBadgeVariant} | undefined => {
  switch (status) {
    case AccountDocumentStatus.Verified: {
      return {
        text: strings[
          'profileSettingsScreen.verification.identityDocument.status.verified'
        ],
        kind: DocumentButtonBadgeVariant.Success,
      };
    }
    case AccountDocumentStatus.OnReview: {
      return {
        text: strings[
          'profileSettingsScreen.verification.identityDocument.status.waiting'
        ],
        kind: DocumentButtonBadgeVariant.Warning,
      };
    }
    case AccountDocumentStatus.Uploaded: {
      return {
        text: strings[
          'profileSettingsScreen.verification.identityDocument.status.uploaded'
        ],
        kind: DocumentButtonBadgeVariant.Success,
      };
    }
    default:
      return;
  }
};

const DocumentsTopView = variance(View)(() => ({
  root: {
    paddingHorizontal: 15,
    paddingVertical: 20,
  },
}));

const DocumentsTopText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    color: theme.palette.textAdditional2,
    fontSize: 14,
    lineHeight: 20,
  },
}));

const DocumentsButtonsView = variance(View)(theme => ({
  root: {
    borderTopWidth: 1,
    borderColor: theme.palette.uiSecondary,
  },
}));

const BottomView = variance(View)(() => ({
  root: {
    marginTop: 20,
    marginHorizontal: 15,
  },
}));

const SubmitButton = variance(Button)(() => ({
  root: {
    marginTop: 20,
  },
}));

const CancelButton = variance(Button)(() => ({
  root: {
    marginTop: 15,
  },
}));

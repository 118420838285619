import {
  useBoolean,
  useRoot,
  useStrings,
  useStyles,
  variance,
} from '@ncwallet-app/core';
import {Button, ButtonVariant} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {View} from 'react-native';

import BuyAmountInput from '../BuyAmountInput';
import FinalBuyMessage from '../FinalBuyMessage/FinalBuyMessage';
import ReceiveAddressType from '../ReceiveAddressType';
import {useReceiveScreenActions} from '../useReceiveScreenActions';
import {WalletInfo} from '../WalletInfo';
import type {BuyTabViewProps} from './BuyTabView';

export default observer(function SmBuyTabView(props: BuyTabViewProps) {
  const {
    walletHeader,
    amount,
    cryptoCurrency,
    addressName,
    networks,
    addressNameShown,
    contractType,
    addressType,
    walletAddress,
    isBalanceHidden,
    onShare,
    onAddresses,
    onAddressTypePress,
    currencyCode,
    error,
    setAmount,
    onSubmit,
    disabled,
    goToRoot,
  } = props;
  const strings = useStrings();
  const {
    platformSpecificComponents: {ButtonPortal},
  } = useRoot();

  const {copyAddressThrottled, shareThrottled} = useReceiveScreenActions(
    onShare,
    walletAddress,
  );

  const [isFinalModalShown, showFinalModalShown, hideFinalModalShown] =
    useBoolean(false);

  const handleBuySubmit = useCallback(async () => {
    const isSubmitSuccessful = await onSubmit();
    if (isSubmitSuccessful) {
      showFinalModalShown();
    }
  }, [onSubmit, showFinalModalShown]);

  const handleConfirmModal = () => {
    hideFinalModalShown();
    goToRoot();
  };

  const receiveAddressStyles = useStyles(theme => ({
    root: {
      paddingTop: 20,
      borderTopWidth: 1,
      borderTopColor: theme.palette.uiSecondary,
    },
  }));

  return (
    <Root>
      <View>
        {!isBalanceHidden && <HeaderWrapper>{walletHeader}</HeaderWrapper>}
        <AmountWrapper>
          <BuyAmountInput
            wideStyle
            amount={amount}
            fractionalDigits={cryptoCurrency?.fractionDigits}
            onChangeAmount={setAmount}
            currency={currencyCode}
            onCurrencyPress={props.onCurrencyPress}
            errorText={error.amount?.description}
          />
        </AmountWrapper>
        <ReceiveAddressType
          style={receiveAddressStyles.root}
          addressName={addressName}
          onAddressTypePress={onAddressTypePress}
          addressNameShown={addressNameShown}
          networks={networks}
          contractType={contractType}
        />
      </View>
      <BuyTabContent>
        <WalletInfo
          goToAddresses={onAddresses}
          copyShown={false}
          shareShown={false}
          addressType={addressType}
          label={strings['receiveCryptoScreen.walletAddressLabel']}
          info={walletAddress}
          onCopy={copyAddressThrottled}
          onShare={shareThrottled}
        />
      </BuyTabContent>

      <BottomContainer>
        <ButtonPortal portalKey="buyButtonPortal">
          <SubmitButton
            title={strings['nextButton']}
            variant={ButtonVariant.Primary}
            onPress={handleBuySubmit}
            disabled={disabled}
          />
        </ButtonPortal>
      </BottomContainer>
      {isFinalModalShown && (
        <FinalBuyMessage
          onConfirm={handleConfirmModal}
          onCancel={hideFinalModalShown}
        />
      )}
    </Root>
  );
});

const Root = variance(View)(() => ({
  root: {
    flex: 1,
    gap: 20,
  },
}));

const BuyTabContent = variance(View)(() => ({
  root: {
    flex: 1,
    gap: 20,
    paddingHorizontal: 15,
  },
}));

const BottomContainer = variance(View)(() => ({
  root: {
    paddingHorizontal: 15,
  },
}));

const SubmitButton = variance(Button)(() => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
  },
}));

const HeaderWrapper = variance(View)(() => ({
  root: {
    paddingBottom: 20,
  },
}));

const AmountWrapper = variance(View)(() => ({
  root: {
    paddingHorizontal: 15,
    paddingBottom: 20,
  },
}));

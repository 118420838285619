import {PermissionStatus, uriToFileData, useRoot} from '@ncwallet-app/core';
import type {PermissionResponse} from 'expo-camera';
import type {MediaLibraryPermissionResponse} from 'expo-image-picker';
import {requestMediaLibraryPermissionsAsync} from 'expo-image-picker';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import type {VerificationFile} from '../../../CommonNavigationContainers/hooks/AccountVerification';
import {
  getVerificationFileFromFs,
  getVerificationFileFromImageLibrary,
  useHandleVerificationFileError,
} from '../../../CommonNavigationContainers/hooks/AccountVerification';
import PromptToChooseIdentityFileScreen from '../../../screens/PromptToChooseIdentityFileScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

const checkPermissionResponse =
  (response: PermissionResponse | MediaLibraryPermissionResponse) =>
  (onError: () => void, onSuccess: () => void) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    if (response.status === PermissionStatus.DENIED && response.canAskAgain) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    if (response.status === PermissionStatus.DENIED && !response.canAskAgain) {
      onError();
      return;
    }

    onSuccess();
  };

export type ListDocumentsBindingProps =
  SmallHomeStackBindingProps<'PromptToChooseIdentityFile'>;

export default observer(function PromptToChooseIdentityFileBinding(
  props: ListDocumentsBindingProps,
) {
  const {navigation} = props;
  const documentTypeId = props.route.params.type;
  const handleVerificationFileError = useHandleVerificationFileError();
  const root = useRoot();

  const handleClose = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const _handleUpload = useCallback(
    async (file: VerificationFile) => {
      const {uri, fileName} = file;
      const uploadRes = await root.accountDocumentManager.uploadFile(
        await uriToFileData(uri),
        documentTypeId,
        fileName,
      );
      if (uploadRes.success) {
        handleClose();
      }
    },
    [documentTypeId, handleClose, root],
  );

  const getFileFromImageLibrary = useCallback(async () => {
    const fileRes = await getVerificationFileFromImageLibrary();
    if (fileRes.success) {
      await _handleUpload(fileRes.right);
    } else {
      handleVerificationFileError(fileRes.left);
    }
  }, [_handleUpload, handleVerificationFileError]);

  const handleFileUpload = useCallback(async () => {
    const fileRes = await getVerificationFileFromFs();
    if (fileRes.success) {
      await _handleUpload(fileRes.right);
    } else {
      handleVerificationFileError(fileRes.left);
    }
  }, [_handleUpload, handleVerificationFileError]);

  const handleCameraPress = useCallback(async () => {
    navigation.navigate('ShowDocumentCamera', {documentTypeId});
  }, [navigation, documentTypeId]);

  const handleUploadPhotoPress = useCallback(async () => {
    const permissionResponse = await requestMediaLibraryPermissionsAsync();
    const continueWith = checkPermissionResponse(permissionResponse);

    continueWith(() => {
      navigation.navigate('PromptDocumentPermission', {
        permissionKind: 'gallery',
      });
    }, getFileFromImageLibrary);
  }, [getFileFromImageLibrary, navigation]);

  return (
    <PromptToChooseIdentityFileScreen
      onCamera={handleCameraPress}
      onUploadPhoto={handleUploadPhotoPress}
      onUploadFile={handleFileUpload}
      onClose={handleClose}
    />
  );
});

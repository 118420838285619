import {useTheme, variance} from '@ncwallet-app/core';
import type {AvailableLanguageCode} from '@ncwallet-app/core/src/Localization/constant';
import {TouchableListItem} from '@ncwallet-app/ui';
import {CheckSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';
import type {SvgProps} from 'react-native-svg';

export type ListItem = {
  Icon: React.ComponentType<SvgProps>;
  text: string;
};

export type LanguageItem = ListItem & {locale: AvailableLanguageCode};

type LanguageSettingsScreenItemProps = {
  item: LanguageItem;
  onPress: () => void;
  selected: boolean;
};

export default observer(function LanguageSettingsListItem(
  props: LanguageSettingsScreenItemProps,
) {
  const {item, onPress, selected} = props;
  const theme = useTheme();
  const Icon = item.Icon;

  return (
    <Root onPress={onPress} testID={`lang-${item.locale}`}>
      <BodyView>
        <Icon width={30} height={22} />
        <LanguageText selected={selected}>{item.text}</LanguageText>
        <CheckIcon>
          {selected && <CheckSvg color={theme.palette.info} />}
        </CheckIcon>
      </BodyView>
    </Root>
  );
});

const Root = variance(TouchableListItem)(() => ({
  root: {},
}));

const BodyView = variance(View)(() => ({
  root: {
    flex: 1,
    flexDirection: 'row',
    marginHorizontal: 25,
    paddingVertical: 20,
  },
}));

const LanguageText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 16,
    marginLeft: 15,
    color: theme.palette.textMain,
  },
  selected: {
    color: theme.palette.info,
  },
}));

const CheckIcon = variance(View)(() => ({
  root: {
    marginLeft: 'auto',
  },
}));

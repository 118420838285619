import {compact, UNKNOWN_ERROR, useRoot, useStrings} from '@ncwallet-app/core';
import type {FallbackRender} from '@sentry/react';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo} from 'react';

import {AuthErrorScreen} from '../../screens/AuthErrorScreen';

export type RenderErrorHandlerProps = {
  error: unknown;
  resetError: () => void;
};

const ErrorsHandler = observer(function RenderErrorsHandler(
  props: RenderErrorHandlerProps,
) {
  const {error, resetError} = props;
  const strings = useStrings();
  const root = useRoot();
  const appVersion = root.deviceInfo.getAppVersionWithBuildNumber();
  const errorMessage = strings['authErrorScreen.title'];
  const errorDescription = strings['authErrorScreen.caption'];
  const cancelText = strings['authErrorScreen.tryAgain'];

  const handleBack = useCallback(() => {
    root.navigationContainer.ref?.resetRoot({
      index: 0,
      routes: [{name: 'Root'}],
    });
    resetError();
  }, [resetError, root.navigationContainer.ref]);

  const errorContent = useMemo(() => {
    const details = root.errorParser.describe({
      kind: UNKNOWN_ERROR,
      raw: error,
      description: error instanceof Error ? error.message : String(error),
    });
    return compact([
      strings['exception.stage.render01'],
      details.summary,
      details.code,
      details.details,
    ]).join('\n');
  }, [error, root, strings]);

  return (
    <AuthErrorScreen
      appVersion={appVersion}
      cancelText={cancelText}
      onCancel={handleBack}
      errorMessage={errorMessage}
      errorDescription={errorDescription}
      onBack={handleBack}
      errorContent={errorContent}
    />
  );
});

export default ErrorsHandler;

export const useFallbackRenderErrorsHandler = () =>
  useCallback<FallbackRender>(props => <ErrorsHandler {...props} />, []);

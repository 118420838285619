import type {
  CryptoAddress,
  CryptoCurrencyCode,
  CurrencyStore,
  DecimalString,
  JsonRpcClient,
  LinkingOptionsProvider,
  Location,
  NCWalletCallScheme,
  NCWalletNotificationScheme,
  Url,
} from '@ncwallet-app/core';
import {getAccountFromState} from '@ncwallet-app/core/src/AccountStore';
import type {AccountStore} from '@ncwallet-app/core/src/AccountStore';
import type {Appearance} from '@ncwallet-app/core/src/Appearance';
import type {ErrorParser} from '@ncwallet-app/core/src/ErrorParser';
import type {FlashMessage} from '@ncwallet-app/core/src/FlashMessage';
import type {Localization} from '@ncwallet-app/core/src/Localization';
import type {AddressNetwork} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import {DepositProvider} from '@ncwallet-app/core/src/NCWalletServer/WalletDeposit/WalletDepositCreateLink';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import {Platform} from 'react-native';
// eslint-disable-next-line import-x/no-extraneous-dependencies
import {InAppBrowser} from 'react-native-inappbrowser-reborn';

import {isChromeExtension} from '../../hooks/isChromeExtension';

export enum BuyLinkError {
  DepositForbidden = 7001,
  DepositCreationError = 7002,
  DepositSettingsNotFound = 7003,
  DepositInactive = 7004,
  InvalidDepositIp = 7005,
  InvalidDepositAmount = 7006,
}

export enum ErrorPresentationType {
  Amount = 'amount',
  Notification = 'notification',
  Modal = 'modal',
}

export type BuyError = Partial<{
  [ErrorPresentationType.Amount]?: {description: string};
  [ErrorPresentationType.Notification]?: {description: string};
  [ErrorPresentationType.Modal]?: {code: BuyLinkError};
}>;

const VALID_AMOUNT_REGEXP = /^[0-9]*[,.]?[0-9]{0,8}$/;

export class BuyLinkBindingState {
  @observable private _amount: DecimalString | undefined;
  @observable private _provider: DepositProvider = DepositProvider.Moonpay;
  @observable private _currency: CryptoCurrencyCode | undefined;
  @observable private _isLoading = false;
  @observable private _buyError: BuyError = {};
  @observable private _address: CryptoAddress | undefined;
  @observable private _network: AddressNetwork | undefined;

  constructor(
    private readonly _root: {
      readonly ncWalletJsonRpcClient: JsonRpcClient<
        NCWalletCallScheme,
        NCWalletNotificationScheme
      >;
      readonly errorParser: ErrorParser;
      readonly currencyStore: CurrencyStore;
      readonly linkingOptionsProvider: LinkingOptionsProvider;
      readonly location: Location;
      readonly localization: Localization;
      readonly appearance: Appearance;
      readonly accountStore: AccountStore;
      readonly flashMessage: FlashMessage;
    },
  ) {
    makeObservable(this);
  }

  @computed get amount() {
    return this._amount;
  }

  @computed get currencyCode() {
    return this._currency;
  }

  @computed get buyError() {
    return this._buyError;
  }

  @computed get isDepositAllowed() {
    if (!this._currency) {
      return;
    }
    return (
      this._root.currencyStore.getCryptoCurrency(this._currency)?.options
        .deposit_is_active === true
    );
  }

  @computed get disabled() {
    return (
      this._amount === undefined || this._amount === '0' || this._isLoading
    );
  }

  @computed get isBalanceHidden() {
    const tags = getAccountFromState(this._root.accountStore.state)?.tags ?? [];
    return tags.includes('buynobalance');
  }

  @action.bound
  setAmount(amount: DecimalString | undefined): void {
    this._buyError = {};

    if (amount === undefined) {
      this._amount = undefined;
      return;
    }

    const isValid = VALID_AMOUNT_REGEXP.test(amount);
    if (!isValid) {
      return;
    }
    this._amount = amount.replace(',', '.');
  }

  @action.bound
  setCurrency(currency: CryptoCurrencyCode): void {
    this._currency = currency;
  }

  @action.bound
  setAddress(address: CryptoAddress): void {
    this._address = address;
  }

  @action.bound
  setNetwork(network: AddressNetwork): void {
    this._network = network;
  }

  @action.bound
  resetError(): void {
    this._buyError = {};
  }

  @action.bound
  private async _getWebViewLink(): Promise<Url | undefined> {
    const currency = this._currency as CryptoCurrencyCode;
    const amount = this._amount;
    const provider = this._provider;

    runInAction(() => {
      this._isLoading = true;
      this._buyError = {};
    });
    const res = await this._root.ncWalletJsonRpcClient.call(
      'wallets.deposit.create_link',
      {
        currency,
        provider,
        address: this._address,
        network: this._network,
        ...((Number(amount) === 0 || Number(amount) > 0) && {amount}),
      },
    );

    if (res.success) {
      runInAction(() => {
        this._isLoading = false;
      });
    }

    if (!res.success) {
      const error = this._root.errorParser.describe(res.left);
      const errorCode = error.code as BuyLinkError | undefined;

      if (errorCode && Object.values(BuyLinkError).includes(errorCode)) {
        runInAction(() => {
          this._isLoading = false;
          const _error = this._parseLimitsError(
            errorCode,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
            (res.left as any).body?.data,
          );
          if (_error.notification?.description) {
            this._root.flashMessage.showMessage({
              title: _error.notification.description,
              variant: 'danger',
            });
            return;
          }
          if (_error.amount) {
            _error.amount.description = _error.amount.description.replace(
              '{currency}',
              this._currency as string,
            );
          }
          this._buyError = _error;
        });
      }
      return;
    }

    return res.right.link as Url;
  }

  @action.bound
  async onSubmit(): Promise<boolean> {
    const url = await this._getWebViewLink();

    if (!url) {
      return false;
    }

    if (isChromeExtension() || Platform.OS === 'web') {
      await this._root.location.open(url);
      return true;
    }

    if (await InAppBrowser.isAvailable()) {
      await InAppBrowser.open(url, {
        preferredBarTintColor: this._root.appearance.theme.palette.primary,
        showInRecents: true,
      });
    } else {
      await this._root.location.open(url);
    }
    return true;
  }

  private _parseLimitsError = (
    erorCode: BuyLinkError,
    data?: {
      min_coin: string;
      max_coin: string;
    },
  ): BuyError => {
    if (
      [BuyLinkError.DepositForbidden, BuyLinkError.InvalidDepositIp].includes(
        erorCode,
      )
    ) {
      return {
        [ErrorPresentationType.Notification]: {
          description: this._root.localization.getTranslation(
            'error.buyCrypto.DepositForbidden',
          ),
        },
      };
    }

    if (erorCode !== BuyLinkError.InvalidDepositAmount) {
      return {
        [ErrorPresentationType.Modal]: {code: erorCode},
      };
    }

    let res = this._root.localization.getTranslation(
      'error.buyCrypto.DepositForbidden',
    );
    if (data === undefined) {
      return {
        [ErrorPresentationType.Modal]: {code: erorCode},
      };
    }

    const {min_coin, max_coin} = data;

    if (min_coin && max_coin) {
      res = this._root.localization
        .getTranslation('error.buyCrypto.Limits')
        .replace('{min}', `[${min_coin}](${min_coin})`)
        .replace('{max}', `[${max_coin}](${max_coin})`);
    } else if (min_coin) {
      res = this._root.localization
        .getTranslation('error.buyCrypto.Limits.Min')
        .replace('{min}', `[${min_coin}](${min_coin})`);
    } else if (max_coin) {
      res = this._root.localization
        .getTranslation('error.buyCrypto.Limits.Max')
        .replace('{max}', `[${max_coin}](${max_coin})`);
    }
    return {amount: {description: res}};
  };
}

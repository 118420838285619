import {useStrings, variance} from '@ncwallet-app/core';
import {ThemeKind} from '@ncwallet-app/core/src/Appearance';
import {TouchableListItem} from '@ncwallet-app/ui';
import {
  CheckSvg,
  LightningSvg,
  LunaSvg,
  SunSvg,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

export type ThemeSelectBodyProps = {
  currentTheme: ThemeKind;
  onChangeTheme: (theme: ThemeKind) => void;
};

export default observer(function ThemeSelect(props: ThemeSelectBodyProps) {
  const strings = useStrings();

  return (
    <LgContainer>
      <Subtitle>{strings['themeSelect.subtitle']}</Subtitle>

      <ThemeTouchable
        onPress={() => {
          props.onChangeTheme(ThemeKind.Auto);
        }}>
        <BodyView>
          <LightningIcon />
          <ThemeTouchableText>
            {strings['themeSelect.buttonAuto']}
          </ThemeTouchableText>
          {props.currentTheme === ThemeKind.Auto && <CheckIcon />}
        </BodyView>
      </ThemeTouchable>

      <ThemeTouchable
        onPress={() => {
          props.onChangeTheme(ThemeKind.Light);
        }}>
        <BodyView>
          <SunIcon />
          <ThemeTouchableText>
            {strings['themeSelect.buttonLight']}
          </ThemeTouchableText>
          {props.currentTheme === ThemeKind.Light && <CheckIcon />}
        </BodyView>
      </ThemeTouchable>

      <ThemeTouchable
        onPress={() => {
          props.onChangeTheme(ThemeKind.Dark);
        }}>
        <BodyView>
          <MoonIcon />
          <ThemeTouchableText>
            {strings['themeSelect.buttonDark']}
          </ThemeTouchableText>
          {props.currentTheme === ThemeKind.Dark && <CheckIcon />}
        </BodyView>
      </ThemeTouchable>
    </LgContainer>
  );
});

const LgContainer = variance(View)(() => ({
  root: {
    paddingHorizontal: 15,
    paddingTop: 10,
    paddingBottom: 0,
    backgroundColor: 'transparent',
  },
}));

const Subtitle = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 14,
    lineHeight: 24,
    color: theme.palette.textAdditional1,
    marginBottom: 20,
    textAlign: 'center',
  },
}));

const ThemeTouchable = variance(TouchableListItem)(theme => ({
  root: {
    marginBottom: 20,
    padding: 15,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: theme.palette.uiAdditional1,
  },
}));

const BodyView = variance(View)(() => ({
  root: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const ThemeTouchableText = variance(Text)(theme => ({
  root: {
    marginHorizontal: 'auto',
    ...theme.fontByWeight('400'),
    fontSize: 16,
    color: theme.palette.textMain,
  },
}));

const CheckIcon = variance(CheckSvg)(
  () => ({
    root: {
      position: 'absolute',
      right: 15,
      top: 0,
    },
  }),
  theme => ({
    color: theme.palette.uiAdditional1,
  }),
);

const LightningIcon = variance(LightningSvg)(
  () => ({
    root: {},
  }),
  theme => ({
    color: theme.palette.textMain,
  }),
);

const SunIcon = variance(SunSvg)(
  () => ({
    root: {},
  }),
  theme => ({
    color: theme.palette.textMain,
  }),
);

const MoonIcon = variance(LunaSvg)(
  () => ({
    root: {},
  }),
  theme => ({
    color: theme.palette.textMain,
  }),
);

// sort-imports-ignore
import App from './App';

import {configureBigNumber, env, extendDayjs} from '@ncwallet-app/core/src';
import UniversalDeviceInfoImpl from '@ncwallet-app/core/src/DeviceInfo/UniversalDeviceInfoImpl';
import {maybeCompleteAuthSession} from 'expo-web-browser';
import React from 'react';
import {createRoot} from 'react-dom/client';

import {Sentry} from './Sentry';

maybeCompleteAuthSession({
  skipRedirectCheck: true,
});

if (!__DEV__) {
  Sentry.init({
    dsn: env.NC_WALLET_SENTRY_DSN_TELEGRAM,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 0.1,
    release: new UniversalDeviceInfoImpl().getAppVersionWithBuildNumber(),
  });
}

extendDayjs();
configureBigNumber();

const element = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(element!);
document.addEventListener('mousedown', e => {
  if (e.button === 1) {
    e.preventDefault();
  } else {
    return null;
  }
});
root.render(<App />);
